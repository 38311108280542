import React from 'react';
import { FaBriefcase } from "react-icons/fa"; // Importing the briefcase icon

const ProfileRole = () => {
  return (
    <div className="profile-role">
      <div className="role-info">
        <div className="role-info-icon">
          <FaBriefcase
            style={{
              fontSize: "25px",
              color: "#000000",
            }}
          />
        </div>
        <div>
          <h4>Product Manager</h4>
          <p>Co-founder of Debate AI</p>
        </div>
      </div>
      <button className="btn-edit-role">Edit</button>
    </div>
  );
}

export default ProfileRole