import mic2 from "../../assets/mic2.jpg";
import "./Hero.css";


const Hero = () => {
    return (
        <div className="landing-page-div">
        <section
            className="hero-section"
            style={{
            backgroundImage: ` url(${mic2})`,
            }}
        >

            <h1 className="headerText">Debate Like a Pro</h1>
            <p className="text">
            AI-powered debates and tutorials for students and professionals. Get
            started for free.
            </p>
            <div className="search-container">
            <div className="search-input-wrapper">
                <i className="fa-solid fa-magnifying-glass search-icon"></i>
                <input
                type="text"
                className="search-input"
                placeholder="What do you want to debate?"
                />
                <button className="search-button">Search</button>
            </div>
            </div>
        </section>
        </div>
    );
};

export default Hero;
