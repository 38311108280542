// src/components/FactChecking.js
import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import config from '../config';  // Import the config file

const FactChecking = ({ token }) => {
    console.log("fact checking");
    const { id } = useParams();
    const [fact, setFact] = useState('');
    const [rating, setRating] = useState('');

    const handleFactCheck = () => {
        axios.post(`${config.apiUrl}/debates/${id}/fact_check/`, { fact, rating }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => console.log('Fact check submitted', response))
        .catch(error => console.error('Error submitting fact check:', error));
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Fact Checking</Typography>
            <TextField
                label="Fact"
                value={fact}
                onChange={e => setFact(e.target.value)}
                fullWidth
                multiline
                margin="normal"
            />
            <TextField
                label="Rating"
                value={rating}
                onChange={e => setRating(e.target.value)}
                fullWidth
                type="number"
                margin="normal"
            />
            <Box mt={2}>
                <Button variant="contained" color="primary" onClick={handleFactCheck}>
                    Submit Fact Check
                </Button>
            </Box>
        </Container>
    );
};

export default FactChecking;
