import React from 'react'
import EthicalConsiderayion from './EthicalConsiderayion';

const CounterArguments = ({document}) => {
  return (
    <div style={{display:'flex', gap:'20px'}}>

      <div style={{flex:'1'}} className="counter-arguments-section">
        <div className="counter-arguments">
          <h2>
            Counter Arguments (
            {document.analysis_results.counter_arguments
              ? document.analysis_results.counter_arguments.length
              : 0}
            )
          </h2>
        </div>

        {/* Counter Arguments Grid */}
        <div className="counter-arguments-grid">
          {document.analysis_results.counter_arguments &&
          document.analysis_results.counter_arguments.length > 0 ? (
            document.analysis_results.counter_arguments.map(
              (argument, index) => (
                <div className="grid-item" key={index}>
                  <p>{argument}</p>
                </div>
              )
            )
          ) : (
            <p>No counter arguments available.</p>
          )}
        </div>
      </div>

      <EthicalConsiderayion document={document}/> 

    </div>
  );
}

export default CounterArguments