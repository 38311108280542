import {  useSelector } from "react-redux";

const Interests = ({ handleInputChange }) => {
  // Access Redux state directly using useSelector
  const { profile, isEditting, errors } = useSelector((state) => state.profile);

  return (
    <div className="interests-section">
      {isEditting ? (
        <>
          <input
            type="text"
            name="interests"
            value={profile.interests}
            onChange={handleInputChange}
            placeholder="Your interests"
            className="interests-input-profile"
            aria-invalid={!!(errors && errors.interests)}
          />
          {errors && errors.interests && (
            <span className="error-message">{errors.interests}</span>
          )}
        </>
      ) : (
       <p style={{ margin: "5px" }}>
        {profile.interests || "No interests added yet"}
      </p>

      )}
    </div>
  );
};

export default Interests;
