import React from "react";
import {
  FaRegCommentDots,
  FaUserFriends,
  FaBullhorn,
  FaFileAlt,
} from "react-icons/fa";
import "./NotificationsReminders.css";

function NotificationsReminders() {
  return (
    <div className="notifications-reminders-container">
      {/* Notifications Section */}
      <div className="notifications-box">
        <h4>Notifications</h4>
        <div className="notification-item">
          <div className="icon">
            <FaRegCommentDots />
          </div>
          <div>
            <p>Direct Messages</p>
            <small>Get notified when you receive a message</small>
          </div>
        </div>
        <div className="notification-item">
          <div className="icon">
            <FaUserFriends />
          </div>
          <div>
            <p>Followers</p>
            <small>Get notified when someone follows you</small>
          </div>
        </div>
        <div className="notification-item">
          <div className="icon">
            <FaBullhorn />
          </div>
          <div>
            <p>Product Updates</p>
            <small>Get notified about new features and updates</small>
          </div>
        </div>
      </div>

      {/* Reminders Section */}
      <div className="reminders-box">
        <h4>Reminders</h4>
        <div className="reminder-item active">
          <div className="icon">
            <FaRegCommentDots />
          </div>
          <div>
            <p>Feedbacks</p>
            <small>You have 2 unviewed feedbacks</small>
          </div>
        </div>
        <div className="reminder-item">
          <div className="icon">
            <FaFileAlt />
          </div>
          <div>
            <p>Documents</p>
            <small>You have 1 document ready for review</small>
          </div>
        </div>
        <div className="reminder-item">
          <div className="icon">
            <FaRegCommentDots />
          </div>
          <div>
            <p>Feedbacks</p>
            <small>You have 2 unviewed feedbacks</small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationsReminders;
