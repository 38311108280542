import React, { useEffect } from "react";
import { motion } from "framer-motion";
import "./Documents.css";
import { FaUpload } from "react-icons/fa";

function Documents({ token, documents, onViewAnalysis, setViewingAnalysis }) {
  useEffect(() => {
    setViewingAnalysis(false);
  }, [setViewingAnalysis]);

  return (
    <div className="documents-container">
      {/* Animated Header Section */}
      <motion.div
        className="documents-header"
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <h2>Document management</h2>
        <p>
          You can upload your own documents or use the model to analyze and
          summarize text. We currently support .pdf, .docx, .txt, and .md files.
          Max file size is 10MB.
        </p>
        <motion.button
          className="btn-upload"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <FaUpload style={{ marginRight: "5px", fontSize: "16px" }} /> Upload
          document
        </motion.button>
      </motion.div>

      <motion.h3
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Uploaded
      </motion.h3>

      <div className="documents-section">
        <ul className="document-list">
          {documents.map((doc, index) => (
            <motion.li
              key={index}
              className="document-item"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{
                duration: 0.6,
                ease: "easeOut",
                delay: index * 0.1,
              }}
            >
              <div className="document-info">
                <div className="document-icon">📄</div>
                <div className="document-details">
                  <h5>{doc.description || "empty"}</h5>
                  <p>
                    {2002} •{" "}
                    {(doc.analysis_results &&
                      doc.analysis_results.word_count) ||
                      "analysis is not ready"}
                  </p>
                </div>
              </div>
              {doc.analysis_status === "completed" ? (
                <motion.button
                  onClick={() => onViewAnalysis(doc)}
                  className="btn-view-summary"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  View Analysis
                </motion.button>
              ) : (
                doc.analysis_status
              )}
            </motion.li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Documents;
