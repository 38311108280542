import './PersonalInfo.css';
import { useSelector } from 'react-redux';
import { FaUser, FaPhone, FaBuilding, FaGraduationCap, FaVenusMars, FaCalendarAlt, FaMapMarkerAlt, FaBriefcase } from 'react-icons/fa';

const PersonalInfo = ({ handleSubmit, handleInputChange }) => {
    const { profile, errors, isEditting } = useSelector((state) => state.profile);

    return (
        <form onSubmit={handleSubmit} className={`form-container ${isEditting ? 'isEditing' : 'notEditing'}`}>
            <div className="form-row">
                <div className="input-container">
                    <label><FaUser className="icon" />First Name</label>
                    {isEditting ? (
                        <input name="first_name" onChange={handleInputChange} type="text" defaultValue={profile.first_name || ""} placeholder="Enter first name" />
                    ) : (
                        <div className="display-text">{profile.first_name || "N/A"}</div>
                    )}
                    {errors && errors.first_name && <span className="error-message">{errors.first_name}</span>}
                </div>
                <div className="input-container">
                    <label><FaUser className="icon" />Last Name</label>
                    {isEditting ? (
                        <input name="last_name" onChange={handleInputChange} type="text" defaultValue={profile.last_name || ""} placeholder="Enter last name" />
                    ) : (
                        <div className="display-text">{profile.last_name || "N/A"}</div>
                    )}
                    {errors && errors.last_name && <span className="error-message">{errors.last_name}</span>}
                </div>
            </div>
            <div className="form-row">
                <div className="input-container">
                    <label><FaVenusMars className="icon" />Gender</label>
                    {isEditting ? (
                        <select name="gender" onChange={handleInputChange} defaultValue={profile.gender || ""}>
                            <option value="">Select gender</option>
                            <option value="M">M</option>
                            <option value="F">F</option>
                        </select>
                    ) : (
                        <div className="display-text">{profile.gender === "M" ? "Male" : profile.gender === "F" ? "Female" : "N/A"}</div>
                    )}
                    {errors && errors.gender && <span className="error-message">{errors.gender}</span>}
                </div>
                <div className="input-container">
                    <label><FaCalendarAlt className="icon" />Birth Date</label>
                    {isEditting ? (
                        <input name="birthdate" onChange={handleInputChange} type="date" defaultValue={profile.birthdate || ""} />
                    ) : (
                        <div className="display-text">{profile.birthdate || "N/A"}</div>
                    )}
                    {errors && errors.birth_date && <span className="error-message">{errors.birth_date}</span>}
                </div>
            </div>
            <div className="form-row">
                <div className="input-container">
                    <label><FaPhone className="icon" />Phone Number</label>
                    {isEditting ? (
                        <input name="phone_number" onChange={handleInputChange} type="tel" defaultValue={profile.phone_number || ""} placeholder="Enter phone number" />
                    ) : (
                        <div className="display-text">{profile.phone_number || "N/A"}</div>
                    )}
                    {errors && errors.phone_number && <span className="error-message">{errors.phone_number}</span>}
                </div>
                <div className="input-container">
                    <label><FaMapMarkerAlt className="icon" />Address/Location</label>
                    {isEditting ? (
                        <input name="address" onChange={handleInputChange} type="text" defaultValue={profile.address || ""} placeholder="Enter address" />
                    ) : (
                        <div className="display-text">{profile.address || "N/A"}</div>
                    )}
                    {errors && errors.address_or_location && <span className="error-message">{errors.address_or_location}</span>}
                </div>
            </div>
        </form>
    );
};

export default PersonalInfo;
