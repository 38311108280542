import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { getDebateFormat } from '../data/static';
import { defaultProfilePicture } from '../data/static';
import './SearchResultsTable.css';
const SearchResultsTable = ({debates}) => {
  return (
     <section className='search-result-section'>
        <div className="search-result-header">
            <div className="drop-arrow"></div> {/* Drop Arrow */}
            <p>Search Results</p>
      </div>
        <table className='search-result-table'>
          <thead className='search-result-head'>
            <tr>
              <th>Motion</th>
              <th>Type</th>
              <th>Status</th>
              <th>Actions</th>
              <th>Debaters</th>
            </tr>
          </thead>
          <tbody>
            {debates.map((debate, index) => (
              <motion.tr
                key={index}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <td data-label="Motion"><p>{debate.motion || "This debate has no motion"}`</p></td>
                <td data-label="Topic"><p>{getDebateFormat(debate.format.description) || "This debate has not description"}</p></td>
                <td data-label="Status"><p>{debate.status || "This debate has no status"}</p></td>
                <td >
                  <Link to={`/debate/${debate.id}`} className="action-button">
                    Go to debate
                  </Link>
                </td>
                <td data-label="Debater">
                  <div className="debater-avatars">
                    {debate.role_assignments.some(
                      (roleAssign) => roleAssign.user && roleAssign.user.profile
                    ) ? (
                      debate.role_assignments.map(
                        (roleAssign, index) =>
                          roleAssign.user &&
                          roleAssign.user.profile && (
                            <img
                              src={
                                roleAssign.user.profile.profile_picture ||
                                defaultProfilePicture
                              }
                              alt={`${roleAssign.user.username}'s profile`}
                              className="participant-image-in-search-result"
                              key={index}
                            />
                          )
                      )
                    ) : (
                      <p>No participants yet</p>
                    )}
                  </div>
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      </section>
  )
}

export default SearchResultsTable