import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Debates.css";

function Debates({setViewingAnalysis}) {
   useEffect(() => {
     setViewingAnalysis(false);
   }, [setViewingAnalysis]);

   
  const navigate = useNavigate();
  const debates = [
    {
      title: "Debate: Should the government ban social media?",
      subtitle: "Should the government ban social media?",
      image: "https://via.placeholder.com/50",
    },
    {
      title: "Debate: Will AI be beneficial for humanity?",
      subtitle: "Will AI be beneficial for humanity?",
      image: "https://via.placeholder.com/50",
    },
    {
      title: "Debate: Is space exploration worth the cost?",
      subtitle: "Is space exploration worth the cost?",
      image: "https://via.placeholder.com/50",
    },
    {
      title: "Debate: Should we abolish the death penalty?",
      subtitle: "Should we abolish the death penalty?",
      image: "https://via.placeholder.com/50",
    },
    {
      title: "Debate: Should animals be used in scientific research?",
      subtitle: "Should animals be used in scientific research?",
      image: "https://via.placeholder.com/50",
    },
    {
      title: "Debate: Is climate change the most important issue?",
      subtitle: "Is climate change the most important issue?",
      image: "https://via.placeholder.com/50",
    },
  ];

  return (
    <div className="debates-container">
      <div className="debates-header">
        <h2>Your debates</h2>
        <div className="debates-tabs">
          <button className="tab active">Past</button>
          <button className="tab">Upcoming</button>
        </div>
        <button onClick={()=>navigate("/create-debate")} className="btn-create">Create a debate</button>
      </div>

      <div className="debates-list">
        {debates.map((debate, index) => (
          <div key={index} className="debate-item">
            <img
              src={debate.image}
              alt={debate.title}
              className="debate-image-analysis"
            />
            <div className="debate-info">
              <h4>{debate.title}</h4>
              <p>{debate.subtitle}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Debates;
