import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";

const CategorySelector = ({ selectedCategory, loading, categories, updateDebateData }) => {
  console.log("category selector");

    const [showCategories, setShowCategories] = useState(false);
    const [localSelectedCategory, setLocalSelectedCategory] = useState(selectedCategory);

    // Load the selected category from localStorage on component mount
    useEffect(() => {
        const savedCategory = localStorage.getItem('selectedCategory');
        if (savedCategory) {
            const parsedCategory = parseInt(savedCategory, 10); // Parse string to integer
            if (parsedCategory !== selectedCategory) {
                setLocalSelectedCategory(parsedCategory);
                updateDebateData(parsedCategory); // Notify parent only if different
            }
        }
    }, [selectedCategory, updateDebateData]); // Only runs on mount or if selectedCategory changes

    // Handle category selection
    const handleChoosingCategory = (categoryId) => {
        setLocalSelectedCategory(categoryId);
        localStorage.setItem('selectedCategory', categoryId.toString()); // Save to localStorage
        updateDebateData(categoryId); // Notify parent about the new selection
        setShowCategories(false); // Close dropdown
    };

    const handleCategoryClick = () => {
        setShowCategories(!showCategories); // Toggle dropdown visibility
    };

    // Find the selected category name based on the selectedCategory ID
    const selectedCategoryName = categories.find(
        (category) => category.id === localSelectedCategory
    )?.name;

    return (
        <>
            <div
                style={{ cursor: "pointer" }}
                className="info-item"
                onClick={handleCategoryClick}
            >
                <i className="fa-solid fa-list"></i>
                <div className="right-div">
                    <h3>Category</h3>
                    <p>
                        {selectedCategoryName
                            ? selectedCategoryName
                            : "Select a category for your debate."}
                    </p>
                    {loading && <p>Loading categories...</p>}
                </div>
            </div>
                   
            <div style={{ position: 'relative', display: 'inline-block' }}> 
                <AnimatePresence>
                    {showCategories && (
                        <motion.ul
                            className="category-list"
                            initial={{ opacity: 0, maxHeight: 0 }}
                            animate={{ opacity: 1, maxHeight: '400px' }}
                            exit={{ opacity: 0, maxHeight: 0 }}
                            transition={{ duration: 0.15, ease: 'easeInOut' }}
                        >
                            {categories.map((category) => (
                                <li
                                    onClick={() => handleChoosingCategory(category.id)}
                                    key={category.id}
                                    className="category-item"
                                >
                                    {category.name}
                                </li>
                            ))}
                        </motion.ul>
                    )}
                </AnimatePresence>
            </div>
        </>
    );
}

export default CategorySelector;
