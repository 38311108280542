import React, { useEffect, useState } from "react";
import "./UploadDocument.css"; // Import the CSS file
import { useParams } from "react-router-dom";
import config from "../../config";
import axios from "axios";
import RightPanel from "./RightPanel";
import LeftPanel from "./LeftPanel";

const UploadDocument = () => {
  const { id } = useParams();
  const [debate, setDebate] = useState(null); // Set to null to signify no data at the beginning
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null); // State to store the selected file for preview
  const token = localStorage.getItem("token");
  const storedUsername = localStorage.getItem("username");

  if (!storedUsername) {
    console.log("No username found in local storage.");
  }

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    const newFiles = files.map((file) => ({
      name: file.name,
      size: (file.size / (1024 * 1024)).toFixed(2) + " MB", // Convert to MB
      type: file.type,
      fileData: file, // Storing the actual file for preview later
    }));

    setUploadedFiles([...uploadedFiles, ...newFiles]);
  };

  const handlePreviewClick = (file) => {
    setSelectedFile(file); // Set the clicked file as the selected file for the preview
  };

  // Load debates
  useEffect(() => {
    axios
      .get(`${config.apiUrl}/debates/${id}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDebate(response.data);
        setLoading(false); // Stop loading when data is fetched
      })
      .catch((error) => {
        console.error("Error fetching debate:", error);
        setLoading(false); // Stop loading if there's an error
      });
  }, [id, token]);

  // Check if debate data is still loading
  if (loading) {
    return <div>Loading...</div>;
  }

  // Check if debate is loaded and role_assignments exists
  if (!debate || !debate.role_assignments) {
    return <div>No debate data found.</div>;
  }

  // Search for the user role
  const userRoleAssignment = debate.role_assignments.find((assignment) => {
    return assignment.user && assignment.user.username === storedUsername;
  });

  return (
    <div className="upload-document-container">    
      <LeftPanel userRoleAssignment={userRoleAssignment} handleFileUpload={handleFileUpload} uploadedFiles={uploadedFiles} handlePreviewClick={handlePreviewClick} id={id} token={token}/>
      <RightPanel selectedFile={selectedFile}/>
    </div>
  );
};

export default UploadDocument;
