import React from 'react';
import "./Teams.css";
import { useNavigate } from 'react-router-dom';

const Teams = ({ debate, id }) => {
    const navigate = useNavigate();
    let sortedParticipants = [];

    if (debate.role_assignments) {
        sortedParticipants = [...debate.role_assignments].sort(
            (a, b) => a.role_template.id - b.role_template.id
        );
        console.log(sortedParticipants);
    }

    const handleAssign = () => {
        navigate(`/debate-roles/${id}`);
    };

    return (
        <div className="participants-grid">
            {sortedParticipants.map((participant, index) => {
                // Find the corresponding role assignment for this participant
                const roleAssignment = debate.role_assignments.find(
                    (assignment) => assignment.role_template.id === participant.role_template.id
                );

                return ( // Add return statement here
                    <div className="participant-card" key={index}>
                        {participant.user ? (
                            <>
                                <div style={{ color: 'white', paddingTop: '6px', paddingBottom: '30px' }} className={roleAssignment?.team_type === 'Opposition' ? 'red-tag' : 'green-tag'}>
                                    {roleAssignment?.team_type === 'Opposition' ? 'Opposition' : 'Proposition'}
                                </div>
                                <div className='img-div bv'>
                                    <img
                                        src={participant.user.profile.profile_picture || 'https://www.gravatar.com/avatar/?d=mp'}

                                        alt={participant.user.username}
                                        className="participant-image"
                                    />
                                </div>
                                <div className="participant-info">
                                    <h4>{participant.user.username}</h4>
                                    <p className="participant-role">{participant.role_template.name}</p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div style={{ color: 'white', paddingTop: '6px', paddingBottom: '30px' }} className={roleAssignment?.team_type === 'Opposition' ? 'red-tag' : 'green-tag'}>
                                    {roleAssignment?.team_type === 'Opposition' ? 'Opposition' : 'Proposition'}
                                </div>
                                <div style={{fontWeight:'bold'}} className='av'>
                                    {participant.role_template.name} is available
                                </div>
                                <div  style={{width:'100%',margin:"0"}}>
                                    <button onClick={handleAssign} className="assign-button">
                                        Assign Me
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default Teams;
