import { useState } from "react";
import axios from "axios";
import "./Register.css";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { Link } from "react-router-dom";
import SuccessDialog from "./SuccessDialog";

export default function Register() {
  console.log("register");
  const [showDialog, setShowDialog] = useState(false);
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Error states for each input field
  const [fullnameError, setFullnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  // Email validation function
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    let hasError = false;

    // Reset error messages
    setEmailError("");
    setFullnameError("");
    setPasswordError("");
    setUsernameError("");
    setConfirmPasswordError("");

    // Trim all fields to remove leading/trailing spaces
    const trimmedFullname = fullname.trim();
    const trimmedEmail = email.trim();
    const trimmedUsername = username.trim();
    // const trimmedPassword = password.trim();
    // const trimmedConfirmPassword = confirmPassword.trim();

    // Full name validation
    if (!trimmedFullname) {
      setFullnameError("Fullname is required");
      hasError = true;
    } else if (trimmedFullname.length < 6) {
      setFullnameError("Full name must be at least 6 characters");
      hasError = true;
    }

    // Email validation
    if (!trimmedEmail) {
      setEmailError("Email is required");
      hasError = true;
    } else if (!validateEmail(trimmedEmail)) {
      setEmailError("Please enter a valid email");
      hasError = true;
    }

    // Username validation (no spaces allowed)
    if (!trimmedUsername) {
      setUsernameError("Username is required");
      hasError = true;
    } else if (/\s/.test(trimmedUsername)) {
      setUsernameError("Username cannot contain spaces");
      hasError = true;
    } else if (trimmedUsername.length < 4) {
      setUsernameError("Username must be at least 4 characters long");
      hasError = true;
    }

    // Password validation
    if (!password) {
      setPasswordError("Password is required");
      hasError = true;
    } else if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      hasError = true;
    }

    if(!confirmPassword){
      setConfirmPasswordError("Confirm password required");
      hasError = true;
    }else if(confirmPassword !== password){
      setConfirmPasswordError(
        "Passwords do not match. Please ensure both password fields are identical."
      );
      hasError = true;
    }

    // Stop submission if there are errors
    if (hasError) {
      return;
    }

    // If no errors, proceed with registration
    try {
      const response = await axios.post(`${config.apiUrl}/register/`, {
        fullname: trimmedFullname,
        username: trimmedUsername,
        email: trimmedEmail,
        password: password,
        confirm_password: confirmPassword,
      });

      // If registration is successful, navigate to another page
      if (response.status === 201) {
        // show a dialog message
        setShowDialog(true);
      }
    } catch (error) {
      console.error("Error during registration:", error); // Log the error details
      
        if (error.response) {
          const errorData = error.response.data;
          console.log("Server response data:", errorData); // Log response data from the server

          // Handle specific field errors
          if (errorData.username) {
            setUsernameError(errorData.username[0]); // Display error message related to the username
          }

          if (errorData.email) {
            setEmailError(errorData.email[0]); // Display error message related to the email
          }

          // If other generic errors occur
          if (!errorData.username && !errorData.email) {
            setError(
              errorData.detail || "Error during registration. Please try again."
            );
          }
        } else {
          setError("An unexpected error occurred. Please try again later.");
        }
    }
  };
  
  function handleClosingDialog(){
    setShowDialog(false);
  }

  return (
    <motion.div
      className="register-container"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <h1>Welcome to RgueAI</h1>
      <motion.form
        className="register-form"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        onSubmit={handleSubmit}
      >
        <div className="form-group">
          <label htmlFor="fullname" className="form-label">
            Full Name
          </label>
          <motion.input
            id="fullname"
            type="text"
            placeholder="Your full name"
            className="form-input"
            whileFocus={{
              borderColor: "#007bff",
              boxShadow: "0 0 8px rgba(0, 123, 255, 0.2)",
            }}
            onChange={(e) => setFullName(e.target.value)}
          />
          {fullnameError && <h6 className="register-error">{fullnameError}</h6>}
        </div>
            <div className="form-group">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <motion.input
                id="email"
                type="email"
                placeholder="Your email address"
                className="form-input"
                whileFocus={{
                  borderColor: "#007bff",
                  boxShadow: "0 0 8px rgba(0, 123, 255, 0.2)",
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && <h6 className="register-error">{emailError}</h6>}
            </div>
            <div className="form-group">
              <label htmlFor="username" className="form-label">
                Username
              </label>
              <motion.input
                id="username"
                type="text"
                placeholder="Choose a username"
                className="form-input"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                whileFocus={{
                  borderColor: "#007bff",
                  boxShadow: "0 0 8px rgba(0, 123, 255, 0.2)",
                }}
              />
              {usernameError && <h6 className="register-error">{usernameError}</h6>}
            </div>


            <div className="form-group">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <motion.input
            id="password"
            type="password"
            placeholder="Create a password"
            className="form-input"
            whileFocus={{
              borderColor: "#007bff",
              boxShadow: "0 0 8px rgba(0, 123, 255, 0.2)",
            }}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && <h6 className="register-error">{passwordError}</h6>}
          </div>
        
            

          <div className="form-group">
            <label htmlFor="confirm-password" className="form-label">
              Confirm password
            </label>
            <motion.input
              id="confirm-password"
              type="password"
              placeholder="Confirm your password"
              className="form-input"
              whileFocus={{
                borderColor: "#007bff",
                boxShadow: "0 0 8px rgba(0, 123, 255, 0.2)",
              }}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {confirmPasswordError && <h6 className="register-error">{confirmPasswordError}</h6>}
          </div>


        {error && <p className="error-message">{error}</p>}
        <motion.button
          type="submit"
          className="register-button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Register
        </motion.button>
      </motion.form>
      <p className="terms-text">
        By signing up, you agree to our <Link to="#">Terms of Service</Link> and{" "}
        <Link to="#">Privacy Policy</Link>
      </p>
      <p className="login-text">
        <Link to="/login">Already have an account?</Link>
      </p>
      <motion.div
        className="additional-actions"
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        <motion.button
          type="button"
          className="login-button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => {
            navigate("/login");
          }}
        >
          Login
        </motion.button>
        <motion.a
          href="#"
          className="forgot-password-link"
          whileHover={{ color: "#0056b3", textDecoration: "underline" }}
        >
          Forgot your password?
        </motion.a>
      </motion.div>
      {showDialog && (
        <SuccessDialog
          isOpen={showDialog}
          onClose={handleClosingDialog}
          message="Congratulations! Your account has been successfully created."
          welcome="Welcome to our community! 🚀"
          buttonText=" Let's Get Started"
        />
      )}
    </motion.div>
  );
}
