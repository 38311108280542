import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu"; // Hamburger icon
import LogoutIcon from "@mui/icons-material/Logout";
import CloseIcon from "@mui/icons-material/Close";

const BurgerMenu = ({
  token,
  handleLogout,
  currentPath,
  profile,
}) => {
  const [menuOpen, setMenuOpen] = useState(false); // State for dropdown menu visibility

  return (
    <>
      {/* Hamburger Icon for smaller devices */}

        {menuOpen ? <CloseIcon style={{zIndex:"1100"}} onClick={()=>{setMenuOpen(false); console.log("clicked");}}/> : <MenuIcon onClick={()=>setMenuOpen(true)}/>}

      {/* Overlay for dropdown menu */}
      {menuOpen && (
        <>
          <div className="dropdown-menu">
            <ul>
              {currentPath !== "/" && (
                <li>
                  <Link to="/" onClick={() => setMenuOpen(false)}>
                    Home
                  </Link>
                </li>
              )}
              {token && (
                <li>
                  <Link to="/dashboard" onClick={() => setMenuOpen(false)}>
                    Dashboard
                  </Link>
                </li>
              )}
              {profile ? (
                <>
                  {currentPath !== "/profile-dashboard" && (
                    <li>
                      <Link
                        to="/profile-dashboard"
                        onClick={() => setMenuOpen(false)}
                      >
                        Profile
                      </Link>
                    </li>
                  )}
                  <li className="log-out-link">
                    <button
                      onClick={() => {
                        setMenuOpen(false);
                        handleLogout();
                      }}
                      className="logoutButton-dropmenu"
                      style={{ color: "black" }}
                    >
                      <LogoutIcon /> Logout
                    </button>
                  </li>
                </>
              ) : (
                <>
                  {currentPath !== "/login" && (
                    <>
                      <li>
                        <Link
                          className="login-button"
                          to="/login"
                          onClick={() => setMenuOpen(false)}
                        >
                          Login
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="signup-button"
                          to="/register"
                          onClick={() => setMenuOpen(false)}
                        >
                          Signup
                        </Link>
                      </li>
                    </>
                  )}
                </>
              )}
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default BurgerMenu;
