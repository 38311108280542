import React, { useState } from "react";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";
import { getDebateFormat } from "../../data/static";
import { defaultProfilePicture } from "../../data/static";
import { motion } from "framer-motion"; // Importing motion from framer-motion

const DebateBoard = ({ debates }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const debatesPerPage = 5; // Set how many debates you want per page

  // Get current debates for the page
  const indexOfLastDebate = currentPage * debatesPerPage;
  const indexOfFirstDebate = indexOfLastDebate - debatesPerPage;
  const currentDebates = debates.slice(indexOfFirstDebate, indexOfLastDebate);

  // Change page handler
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <motion.div
      className="debate-list"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }} // Animation for the debate list container
    >
      <section>
        <h2>Join as a Debater</h2>
        <table>
          <thead>
            <tr>
              <th>Motion</th>
              <th>Type</th>
              <th>Status</th>
              <th>Actions</th>
              <th>Debaters</th>
            </tr>
          </thead>
          <tbody>
            {currentDebates
              .filter((debate) =>
                debate.role_assignments.some(
                  (roleAssign) => roleAssign.user === null
                )
              )
              .map((debate, index) => (
                <motion.tr
                  key={index}
                  initial={{ opacity: 0, y: 10 }} // Start off hidden and slightly below
                  animate={{ opacity: 1, y: 0 }} // Animate to full opacity and original position
                  transition={{ duration: 0.3, delay: index * 0.1 }} // Stagger animation for each row
                >
                  <td data-label="Topic">{debate.motion || "This debate has no motion"}</td>
                  <td data-label="Topic">{getDebateFormat(debate.format.description)}/</td>
                  <td data-label="Topic">{debate.status || "This debate has no status"}</td>
                  <td data-label="Actions">
                    <Link to={`/debate/${debate.id}`} className="action-button">
                      Go to debate
                    </Link>
                  </td>
                  <td data-label="Debater">
                    <div className="debate-participants">
                      {debate.role_assignments.some(
                        (roleAssign) =>
                          roleAssign.user && roleAssign.user.profile
                      ) ? (
                        debate.role_assignments.map(
                          (roleAssign, index) =>
                            roleAssign.user &&
                            roleAssign.user.profile && (
                              <img
                                src={
                                  roleAssign.user.profile.profile_picture ||
                                  defaultProfilePicture
                                }
                                alt={`${roleAssign.user.username}'s profile`}
                                className="participant-image"
                                key={index}
                              />
                            )
                        )
                      ) : (
                        <p>No participants yet</p>
                      )}
                    </div>
                  </td>
                </motion.tr>
              ))}
          </tbody>
        </table>
      </section>

      <section>
        <h2>Upcoming Debates</h2>
        <table>
          <thead>
            <tr>
              <th>Motion</th>
              <th>Type</th>
              <th>Status</th>
              <th>Actions</th>
              <th>Debaters</th>
            </tr>
          </thead>
          <tbody>
            {currentDebates.map((debate, index) => (
              <motion.tr
                key={index}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <td data-label="Topic">{debate.motion || "This debate has no motion"}</td>
                <td data-label="Topic">{getDebateFormat(debate.format.description)}</td>
                <td data-label="Topic">{debate.status}</td>
                <td data-label="Actions">
                  <Link to={`/debate/${debate.id}`} className="action-button">
                    Go to debate
                  </Link>
                </td>
                <td data-label="Debater">
                  <div className="debater-avatars">
                    {debate.role_assignments.some(
                      (roleAssign) => roleAssign.user && roleAssign.user.profile
                    ) ? (
                      debate.role_assignments.map(
                        (roleAssign, index) =>
                          roleAssign.user &&
                          roleAssign.user.profile && (
                            <img
                              src={
                                roleAssign.user.profile.profile_picture ||
                                defaultProfilePicture
                              }
                              alt={`${roleAssign.user.username}'s profile`}
                              className="participant-image"
                              key={index}
                            />
                          )
                      )
                    ) : (
                      <p>No participants yet</p>
                    )}
                  </div>
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      </section>

      <Pagination
        debatesPerPage={debatesPerPage}
        totalDebates={debates.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </motion.div>
  );
};

export default DebateBoard;
