import React from "react";
import "./GetStarted.css";
import { useNavigate } from "react-router-dom";
import { motion, useInView } from "framer-motion";

const GetStarted = ({ token }) => {
  console.log("get started");

    const navigate = useNavigate();

    const handleGetStarted = () => {
        if (token) {
        navigate("/debates");
        } else {
        navigate("/login");
        }
    };

    // Ref for scroll-triggered animation
    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: true, margin: "-100px" });

    return (
        <motion.section
        className="cta-section"
        ref={ref}
        initial={{ opacity: 0 }}
        animate={isInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: 0.8 }}
        >
        <motion.h1
            className="cta-heading"
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }}
        >
            Start Debating Today
        </motion.h1>

        <motion.p
            className="cta-description"
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.5, delay: 0.2 }}
        >
            Rgue.ai is the leading platform for AI-powered debates and educational
            content. Our AI tutor can help you improve your debating skills, and our
            platform hosts tournaments and leagues for students and university
            debate teams.
        </motion.p>

        <motion.button
            onClick={handleGetStarted}
            className="cta-button"
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
        >
            Get Started for Free
        </motion.button>
        </motion.section>
  );
};

export default GetStarted;
