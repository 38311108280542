import axios from 'axios';
import React, { useState } from 'react'
import config from '../../config';

const LeftPanel = ({userRoleAssignment, handleFileUpload, uploadedFiles, handlePreviewClick, id,token}) => {

  const [loading, setLoading] = useState(false);
  const formData = new FormData();
  const [description, setDescription] = useState(null);
 

  function handleSubmittingDocuments(){
     if (uploadedFiles.length > 0) {

      setLoading(true);

       uploadedFiles.forEach((file, index) => {
         formData.append(`file`, file.fileData);
       });
       formData.append("debate",id);
       formData.append("description",description);
       
         axios
           .post(`${config.apiUrl}/documents/`, formData, {
             headers: {
               Authorization: `Bearer ${token}`,
               "Content-Type": "multipart/form-data",
             },
           })
           .then((response) => {
             console.log("Documents submitted successfully:", response.data);
             alert("Documents submitted successfully!");
               setLoading(false);
           }).catch((error)=>{
              console.error("Error submitting documents:", error);
              alert("Error submitting documents. Please try again later.");
                setLoading(false);
           });
         
     }else{
      alert("Include at least one file to submit");
     }
    
  }

  function handleDescription(event){
      setDescription(event.target.value);
      console.log(event.target.value);
  }
  return (
    <div className="upload-panel">
      <h2>Upload your reference documents</h2>
      <p>
        ⚠️ Make sure to upload all the documents that you will rely on in the
        debate. Arguments, Claims, and Discussion points you will raise in the
        debate will be mapped to your uploaded docs for credibility.
      </p>

      <div className="input-container">
        <label htmlFor="debateRole" className="label">
          Your debate role
        </label>
        <div id="debateRole" name="debateRole" className="select">
          <span className="radio-checkmark"></span>
          <span disabled value="Leader of Opposition">
            {userRoleAssignment
              ? userRoleAssignment.role_template.name
              : "No role assigned"}
          </span>
        </div>
      </div>

      <div className="file-upload-container">
        <h4
          style={{
            fontFamily: "Manrope, Inter",
            fontSize: "18px",
            marginBottom: "5px",
          }}
        >
          File requirements
        </h4>
        <input onChange={(event)=>{handleDescription(event)}} type='text' placeholder='description'/>
        <p>
          <strong>File type</strong>
          <br />
          <span style={{ color: "#4F7396" }}>
            Must be a PDF, Word, or Google Doc
          </span>
          <br />
          <br />
          <strong>File size</strong>
          <br />
          <span style={{ color: "#4F7396" }}>Maximum file size: 25MB</span>
        </p>

        <div className="file-upload-container">
          <label htmlFor="file-upload" className="custom-file-upload">
            <span>Choose file</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="upload-icon"
            >
              <path d="M16 16v6H8v-6H3l9-9 9 9h-5z" />
            </svg>
          </label>
          <input
            id="file-upload"
            type="file"
            multiple
            onChange={handleFileUpload}
            className="file-input"
          />
        </div>

        {/* Uploaded Files List */}
        {uploadedFiles && (
          <ul className="uploaded-files-list">
            {uploadedFiles.map((file, index) => {
              return (
                <li key={index}>
                  <i className="fa-solid fa-file"></i>
                  <span>{file.name}</span>
                  <button onClick={() => handlePreviewClick(file)}>
                    Preview
                  </button>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <button
        disabled={uploadedFiles.length > 0 ? false : true}
        onClick={handleSubmittingDocuments}
      >
        Submit Documents
      </button>
      {loading && (
        <div className="loading-spinner-login">
          <div className="spinner-login"></div>
        </div>
      )}
    </div>
  );
}

export default LeftPanel