import React, { useState, useEffect, useCallback } from "react";
import { Avatar, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../config";
import LogoutIcon from "@mui/icons-material/Logout";
import "./NavBar.css";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserName } from "../redux/userSlice";
import BurgerMenu from "./Navbar/BurgerMenu";

const NavBar = ({ token, setToken }) => {
  const [profile, setProfile] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false); // State for screen size
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentPath = location.pathname;

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const handleLogout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("username");
    dispatch(setUserName(null));
    navigate("/");
    window.location.reload();
  }, [dispatch, navigate]);

  
    const handleTokenRefresh = useCallback(async () => {
    const refreshToken = localStorage.getItem("refreshToken");

    if (!refreshToken) {
      console.error("No refresh token available. Logging out...");
      handleLogout();
      return;
    }

    try {
      const response = await axios.post(`${config.apiUrl}/token/refresh`, {
        refresh: refreshToken,
      });

      const newAccessToken = response.data.access;
      localStorage.setItem("token", newAccessToken);
      setToken(newAccessToken);

      const profileResponse = await axios.get(`${config.apiUrl}/profiles/me/`, {
        headers: {
          Authorization: `Bearer ${newAccessToken}`,
        },
      });
      setProfile(profileResponse.data);
    } catch (error) {
      console.error("Error refreshing token:", error);
      handleLogout();
    }
  }, [setToken, handleLogout]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (token) {
          const response = await axios.get(`${config.apiUrl}/profiles/me/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setProfile(response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await handleTokenRefresh();
        } else {
          console.error("Error fetching profile:", error);
        }
      }
    };

    fetchProfile();
  }, [token, handleTokenRefresh]);

 

  return (
    <nav>
      <div className="left-nav">
        <Link to="/" className="logo-link">
          <svg
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="logo"
          >
            <path
              d="M4 4H17.3334V17.3334H30.6666V30.6666H44V44H4V4Z"
              fill="currentColor"
            ></path>
          </svg>
          <h1 style={{ fontSize: "18px", marginLeft: "10px" }}>RgueAI</h1>
        </Link>
      </div>
      
      <div className="right-nav">
        {isSmallScreen ? (
         <BurgerMenu token={token} handleLogout={handleLogout} currentPath={currentPath} profile={profile}/>
        ) : (
          <>
            {/* Links for larger screens */}
            <div className="links-div">
              <ul className="links">
                <li>{currentPath !== "/" && <Link to="/">Home</Link>}</li>
                <li>{token ? <Link to="/dashboard">Dashboard</Link> : null}</li>
              </ul>
            </div>

            <div className="nav-buttons">
              {profile ? (
                <>
                  {currentPath !== "/profile-dashboard" && (
                    <Link to="/profile-dashboard">
                      <Avatar
                        alt="Profile Picture"
                        src={profile.profile_picture || ""}
                        className="profileAvatar"
                      />
                    </Link>
                  )}
                  <IconButton onClick={handleLogout} className="logoutButton">
                    <LogoutIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  {currentPath !== "/login" && (
                    <>
                      <Link className="login-button" to="/login">
                        Login
                      </Link>
                      <Link className="signup-button" to="/register">
                        Signup
                      </Link>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
