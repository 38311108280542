import React from 'react'
import badg1 from "../../assets/badg1.png";
import badg2 from "../../assets/badge2.png";
import badg3 from "../../assets/badge3.png";
const Badges = () => {
  return (
    <div className="profile-badges">
      <h3>Badges</h3>
      <div className="badges-list">
        <img src={badg1} alt="Badge 1" className="badge-profile" />
        <img src={badg2} alt="Badge 2" className="badge-profile" />
        <img src={badg3} alt="Badge 3" className="badge-profile" />
      </div>
    </div>
  );
}

export default Badges