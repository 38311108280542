import React from 'react'
import { getProgressBarColor } from './AnalysisHeader';
const Scores = ({document}) => {
  return (
    <div className="scores">
      <h2>Relevance Score</h2>
      <p
        className={`up-score ${
          document.analysis_results.relevance_score === null ||
          document.analysis_results.relevance_score === undefined
            ? "missing"
            : ""
        }`}
      >
        {document.analysis_results.relevance_score !== null &&
        document.analysis_results.relevance_score !== undefined ? (
          <div className="progress-bar">
            <div
              style={{
                width: `${(
                  document.analysis_results.relevance_score * 100
                ).toFixed(0)}%`,
                backgroundColor: getProgressBarColor(
                  document.analysis_results.relevance_score * 100
                ),
                borderRight: "2px solid black",
                fontSize: "10px",
              }}
              className="progress "
            >
              {document.analysis_results.relevance_score * 100 + "%"}
            </div>
          </div>
        ) : (
          "Missing"
        )}
      </p>

      <h2>Credibility Score</h2>
      <p
        className={`down-score ${
          document.analysis_results.credibility_score === null ||
          document.analysis_results.credibility_score === undefined
            ? "missing"
            : ""
        }`}
      >
        {document.analysis_results.credibility_score !== null &&
        document.analysis_results.credibility_score !== undefined ? (
          <div className="progress-bar">
            <div
              style={{
                width: `${(
                  document.analysis_results.credibility_score * 100
                ).toFixed(0)}%`,
                backgroundColor: getProgressBarColor(
                  document.analysis_results.credibility_score * 100
                ),
                borderRight: "2px solid black",
                fontSize: "10px",
              }}
              className="progress "
            >
              {document.analysis_results.credibility_score * 100 + "%"}
            </div>
          </div>
        ) : (
          "Missing"
        )}
      </p>
    </div>
  );
}

export default Scores