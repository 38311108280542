import React, { useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "./Analysis.css";

const data = [
  { name: "Jan 2020", rating: 4 },
  { name: "Jul 2020", rating: 7 },
  { name: "Jan 2021", rating: 5 },
  { name: "Jul 2021", rating: 9 },
  { name: "Jan 2022", rating: 6 },
];

function Analysis({documents, setViewingAnalysis}) {
   useEffect(()=>{
     setViewingAnalysis(false);
  },[setViewingAnalysis]);
  return (
    <div className="analysis-container">
      <div className="analysis-header">
        <h2>Your debating performance</h2>
        <div className="analysis-tabs">
          <button className="tab active">Overview</button>
          <button className="tab">Debater rating</button>
          <button className="tab">Win-loss ratio</button>
          <button className="tab">Debate topics</button>
          <button className="tab">Debate style</button>
        </div>
      </div>

      <div className="stats-section">
        <div className="stat-box">
          <h3>1,200</h3>
          <p>Lifetime AI debates</p>
        </div>
        <div className="stat-box">
          <h3>7.8</h3>
          <p>Average debater rating</p>
        </div>
        <div className="stat-box">
          <h3>1,500</h3>
          <p>Lifetime human debates</p>
        </div>
        <div className="stat-box">
          <h3>2,300</h3>
          <p>Lifetime wins</p>
        </div>
        <div className="stat-box">
          <h3>4,000</h3>
          <p>Lifetime losses</p>
        </div>
      </div>

      <div className="charts-section">
        <div className="chart-box">
          <h4>Debater rating over time</h4>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="rating"
                stroke="#007bff"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-box">
          <h4>AI debate wins over time</h4>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="rating"
                stroke="#007bff"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="chart-box">
        <h4>AI debate losses over time</h4>
        <ResponsiveContainer width="100%" height={200}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="rating"
              stroke="#007bff"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default Analysis;
