import React from 'react'
import { TextField } from "@mui/material";
import { useState } from 'react';
import { motion, AnimatePresence } from "framer-motion"; // Import necessary components from framer-motion

// Helper function to format the date
const formatDate = (dateString) => {
    if (!dateString) return "Schedule the debate for later.";
    const date = new Date(dateString);
    const options = {
        weekday: "long", // Monday, Tuesday, etc.
        year: "numeric", // Full year
        month: "long", // Full month name
        day: "numeric", // Day of the month
        hour: "2-digit", // Hour in 2-digit format
        minute: "2-digit", // Minute in 2-digit format
    };

return new Intl.DateTimeFormat("en-US", options).format(date);
};
const EndTimeSelector = ({end_time, handleChange}) => {
    
      console.log("end time selector");


    const [isTextFieldVisible, setTextFieldVisible] = useState(false);
    const handleDivClick = () => {
        setTextFieldVisible((oldstate)=>{
            return !oldstate;
        });
    };

    const handleBlur = () => {
        setTimeout(()=>{
            setTextFieldVisible(false);
        },250);

    };
    return (
        <>
            <div style={{cursor:'pointer'}} className="info-item" onClick={handleDivClick} >
                <i className="fa-solid fa-clock"></i>
                <div className="right-div">
                    <h3>End time</h3>
                    <p>{end_time? formatDate(end_time) :'Schedule the debate for later.'}</p>
                </div>
            </div>

            <AnimatePresence>
            {isTextFieldVisible && (
            <motion.div
                initial={{ opacity: 0, y: -10 }} // Initial state for motion
                animate={{ opacity: 1, y: 0 }} // Animate to this state
                exit={{ opacity: 0, y: -10 }} // Exit state when removed
                transition={{ duration: 0.3 }} // Duration of the animation
            >
                <TextField
                label="End Time"
                name="end_time"
                type="datetime-local"
                value={end_time}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                required
                autoFocus
                sx={{
                    marginTop: 2,
                    backgroundColor: "#fff", // White background
                    borderRadius: 2, // Rounded corners
                    "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        borderColor: "#ddd", // Light border color
                    },
                    "&:hover fieldset": {
                        borderColor: "#aaa", // Darker border on hover
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: "#333", // Darker border on focus
                    },
                    },
                    "& .MuiInputLabel-root": {
                    color: "#666", // Label color
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                    color: "#333", // Label color on focus
                    },
                }}
                />
            </motion.div>
            )}
        </AnimatePresence>
        </>
    )
}

export default EndTimeSelector