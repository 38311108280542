// /redux/store.js

import { configureStore } from "@reduxjs/toolkit";
import roleReducer from "./roleSlice"; // Existing slice
import roleTemplatesReducer from "./roleTemplatesSlice"; // Import the new slice
import userReducer from './userSlice'; // Import the user slice
import roleAssignmentsReducer from "./roleAssignmentsSlice"; // Import the new roleAssignments slice
import profileReducer from "./ProfileSlice"


const store = configureStore({
    reducer: {
        user: userReducer, // Add the user reducer
        role: roleReducer, // Existing reducer
        roleTemplates: roleTemplatesReducer, // Add the new roleTemplates reducer
        roleAssignments: roleAssignmentsReducer, // Manage role assignments state
        profile:profileReducer,

    },
});

export default store;
