import React, { useState } from 'react';
import Interests from '../ProfileComponents/Interests';
import ProfilePicture from '../ProfileComponents/ProfilePicture';
import { useDispatch, useSelector } from 'react-redux';
import { setIsEditing, setNewPicture, clearErrors, setProfile } from '../../redux/ProfileSlice';

const ProfileInfo = ({ username, handleInputChange, handleSubmit, handleImageChange }) => {
  const { profile, isEditting, previewPicture, status } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const [originalProfile, setOriginalProfile] = useState(null);

  const handleEdit = () => {
    setOriginalProfile(profile); // Save the original profile before editing
    dispatch(setIsEditing(true));
  };

  const handleCancel = () => {
    dispatch(setIsEditing(false)); // Stop editing mode
    dispatch(setNewPicture(null)); // Reset new picture
    dispatch(clearErrors()); // Clear any existing errors
    if (originalProfile) {
      dispatch(setProfile(originalProfile)); // Restore the original profile data
    }
  };

  return (
    <>
      <ProfilePicture handleImageChange={handleImageChange} previewPicture={previewPicture} />
      <div className="profile-info">
        <h2>{username || 'Guest'}</h2>
        <Interests handleInputChange={handleInputChange} />
        <p style={{ margin: "5px" }} className="profile-subtitle">
          Pro Member
        </p>
        <p className="profile-follow">3.5k followers • 1.2k following</p>
        <div className="profile-actions">
          {isEditting ? (
            <>
              <button className="btn-save" onClick={handleSubmit}>
                Save Changes
              </button>
              <button className="btn-cancel" onClick={handleCancel}>
                Cancel
              </button>
            </>
          ) : (
            <>
              <button className="btn-edit" onClick={handleEdit}>
                Edit Profile
              </button>
              <button className="btn-upgrade">Upgrade</button>
            </>
          )}
        </div>
        {status === "loading" &&  <div className="loading-spinner-createdebate">
                <div className="spinner-createdebate"></div>
              </div>}
      </div>
    </>
  );
};

export default React.memo(ProfileInfo);
