import React, { useEffect, useState } from "react";
import "./SidebarMenu.css";
import Sidebar from "./SideBar";
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import Debates from "./Debates";
import Documents from "./Documents";
import Analysis from "./Analysis";
import DocumentAnalysis from "./DocumentAnalysis"; 
import axios from "axios";
import config from "../../config";
import {useDispatch, useSelector} from "react-redux";
import { setProfile } from "../../redux/ProfileSlice";
const SidebarMenu = () => {

  const [token, setToken] = useState(localStorage.getItem("token"));
  const [selectedView, setSelectedView] = useState("dashboard");
  const [username, setUserName] = useState(localStorage.getItem("username"));
  const [documents, setDocuments] = useState(null);
  const [viewingAnalysis, setViewingAnalysis] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const dispatch = useDispatch();
  const {profile} = useSelector((state)=> state.profile);

  // Fetch documents
  useEffect(() => {
    axios
      .get(`${config.apiUrl}/documents/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const parsedData = response.data.map((item) => ({
          ...item,
          analysis_results: item.analysis_results
            ? JSON.parse(item.analysis_results)
            : null,
        }));
        setDocuments(parsedData);
      })
      .catch((error) => {
        console.log("Error fetching documents: " + error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          setToken(null);
        }
      });
  }, [token]);

  // Synchronize token from localStorage
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  // Fetch profile
  useEffect(() => {
    if (token) {
      axios
        .get(`${config.apiUrl}/profiles/me/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => dispatch(setProfile(response.data)))
        .catch((error) => {
          console.error("Error fetching profile:", error);
          if (error.response && error.response.status === 401) {
            localStorage.removeItem("token");
            setToken(null);
          }
        });
    }
  }, [token, dispatch]);

  useEffect(() => {
    const name = localStorage.getItem("username");
    if (name) {
      setUserName(name);
    }
  }, []);

  // Handle the token missing case
  if (!token) {
    return <div>Please log in to access this page.</div>;
  }

  return (
    <div className="main-doc-analysis">
      <Sidebar
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        profile={profile}
        username={username}
      />
      {selectedView === "dashboard" && (
        <Dashboard
          setViewingAnalysis={setViewingAnalysis}
          username={username}
          documents={documents}
        />
      )}
      {selectedView === "profile" && (
        <Profile
          setViewingAnalysis={setViewingAnalysis}
          profile={profile}
          username={username}
          token={token}
        />
      )}
      {selectedView === "debates" && (
        <Debates setViewingAnalysis={setViewingAnalysis} />
      )}
      {selectedView === "documents" && !viewingAnalysis && (
        <Documents
          setViewingAnalysis={setViewingAnalysis}
          token={token}
          documents={documents}
          onViewAnalysis={(doc) => {
            setSelectedDocument(doc);
            setViewingAnalysis(true);
          }}
        />
      )}
      {viewingAnalysis && selectedDocument && (
        <DocumentAnalysis
          document={selectedDocument}
          onBack={() => setViewingAnalysis(false)}
        />
      )}
      {selectedView === "analytics" && (
        <Analysis
          setViewingAnalysis={setViewingAnalysis}
          documents={documents}
        />
      )}
    </div>
  );
};

export default SidebarMenu;
