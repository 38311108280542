import React, { useState, useEffect, useCallback } from "react";
import "./Profile.css";
import { clearLocalStorage } from "../../data/static";
import ProfileInfo from "./ProfileInfo";
import ProfileContent from "./ProfileContent";
import { useDispatch, useSelector } from "react-redux";
import { setProfile, setNewPicture, setIsEditing, clearErrors, updateProfile, setErrors } from "../../redux/ProfileSlice";
import PersonalInfo from "./PersonalInfo";
const formatDate = (dateString) => {
  // If the input is already in the correct format or is empty, return it directly
  if (!dateString) return "";
  
  // Check if the input is a valid date, if not, return an empty string
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    console.warn("Invalid date format:", dateString);
    return ""; // Return empty if the date is invalid
  }
  
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};


function Profile({ username, setViewingAnalysis, token }) {
  const dispatch = useDispatch();
  const { profile, newPicture, isEditting } = useSelector((state) => state.profile);
  const [selectedFile, setSelectedFile] = useState(null); // Local state for the file object
  const [current_content, setCurrentContent] = useState("activity");

const handleInputChange = useCallback((e) => {
    dispatch(clearErrors());
    const { name, value } = e.target;

    // Directly set the value from input[type="date"]
    if (name === 'birthdate') {
        console.log(`Setting birthdate to: ${value}`); // Debug log
        dispatch(setProfile({ birth_date: value }));
    } else {
        dispatch(setProfile({ [name]: value }));
    }
}, [dispatch]);


  const handleImageChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const newImageUrl = URL.createObjectURL(file);
      setSelectedFile(file); // Store the File object locally
      dispatch(setNewPicture({ previewUrl: newImageUrl })); // Only store the preview URL in Redux
    } else {
      dispatch(setErrors({ profile_picture: "Please upload a valid image file." }));
      console.log("un appropriate image");
    }
  }, [dispatch]);

const handleSubmit = async (e) => {
  e.preventDefault();

  const errors = validateData(profile);

  if (Object.keys(errors).length > 0) {
    dispatch(setErrors(errors));
    return;
  }

  const formData = new FormData();
  formData.append('bio', profile.bio || "");
  formData.append('interests', profile.interests || "");
  formData.append('first_name', profile.first_name || "");
  formData.append('last_name', profile.last_name || "");
  formData.append('gender', profile.gender || "");
  formData.append('birthdate', profile.birthdate ? formatDate(profile.birthdate) : ""); // Make sure it's in YYYY-MM-DD format
  formData.append('phone_number', profile.phone_number || "");
  formData.append('address', profile.address || ""); // Make sure this matches your backend field

  formData.append('education', profile.education || "");
  formData.append('current_job', profile.current_job || "");

  // Attach the profile picture if available
  if (selectedFile) {
    formData.append('profile_picture', selectedFile);
  }



  try {
    // Pass the token separately instead of adding it to FormData
    await dispatch(updateProfile({ formData, token }));
    dispatch(clearErrors());
  } catch (error) {
    console.error("Failed to update profile:", error);
  }
};



  useEffect(() => {
    dispatch(setIsEditing(false));
    setViewingAnalysis(false);
  }, [dispatch, setViewingAnalysis]);

  useEffect(() => {
    clearLocalStorage(); // Clear only when the component mounts, if needed.
  }, []);

  useEffect(() => {
    return () => {
      if (newPicture?.previewUrl) {
        URL.revokeObjectURL(newPicture.previewUrl);
      }
    };
  }, [newPicture]);

  const validateData = (profile) =>{
    const errors = {};
    if (!profile.bio) {
      errors.bio = "Bio is required.";
    }
    if (!profile.interests) {
      errors.interests = "Interests are required.";
    }
    if(!profile.first_name){
      errors.first_name = "First Name is required.";
    }
    if(!profile.last_name){
      errors.last_name = "Last Name is required.";
    }
    if(!profile.gender){
      errors.gender = "gender is required";
    }
    if(!profile.birthdate){
      errors.birthdate = "Birth Date is required";
    }
    if(!profile.phone_number){
      errors.phone_number = "Phone Number is required";
    }
    if(!profile.address){
      errors.address = "Address/Location is required";
    }
    if(!profile.education){ 
      errors.education = "Education is required"; 
    }
    if(!profile.current_job){
      errors.current_job = "Current Job is required";
    }
    return errors;
  }

  return (
    <div className="profile-container">
      <div className="profile-header">
        <ProfileInfo
          username={username}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
        />
          <PersonalInfo handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
      </div>
      <div className="profile-tabs">
        <button onClick={()=>setCurrentContent("activity")} className={`tab ${current_content === "activity"? 'active':''}`}>Activity</button>
        <button onClick={()=>setCurrentContent("achievements")} className={`tab ${current_content === "achievements"? 'active':''}`}>Achievements</button>
        {/* <button onClick={()=>setCurrentContent("info")} className={`tab ${current_content === "info" ? 'active':''}`}>Info</button> */}
      </div>
      {(current_content === "activity" && !isEditting) && <ProfileContent handleInputChange={handleInputChange} />}
     
    </div>
  );
}

export default Profile;
