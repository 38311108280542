import React from 'react'

const PotentialRebuttals = ({document}) => {
  return (
    <div style={{display:'flex', gap:'20px', marginTop:'20px'}}>
      <div style={{flex:'1'}} className="potential-rebuttals-section">
        <div className="potential-rebuttals">
          <h2>
            Potential Rebuttals (
            {document.analysis_results.potential_rebuttals
              ? document.analysis_results.potential_rebuttals.length
              : 0}
            )
          </h2>
        </div>

        {/* Potential Rebuttals Grid */}
        <div className="potential-rebuttals-grid">
          {document.analysis_results.potential_rebuttals &&
          document.analysis_results.potential_rebuttals.length > 0 ? (
            document.analysis_results.potential_rebuttals.map(
              (rebuttal, index) => (
                <div className="grid-item" key={index}>
                  <p>{rebuttal}</p>
                </div>
              )
            )
          ) : (
            <p>No potential rebuttals available.</p>
          )}
        </div>
      </div>
      {/* //////////////////////////////////////////// */}
       <div style={{flex:1}} className="cross-references-section">
        <div className="cross-references">
          <h2>
            Cross References (
            {document.analysis_results.cross_references
              ? document.analysis_results.cross_references.length
              : 0}
            )
          </h2>
        </div>

        {/* Cross References Grid */}
        <div className="cross-references-grid">
          {document.analysis_results.cross_references &&
          document.analysis_results.cross_references.length > 0 ? (
            document.analysis_results.cross_references.map(
              (reference, index) => (
                <div className="grid-item" key={index}>
                  <p>{reference}</p>
                </div>
              )
            )
          ) : (
            <p>No cross references available.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default PotentialRebuttals