import React from 'react'
import ProfileRole from '../ProfileComponents/ProfileRole'
import ProfileBio from '../ProfileComponents/ProfileBio'
import Badges from '../ProfileComponents/Badges'

const ProfileContent = ({ handleInputChange}) => {
  return (
    <div className="profile-content">
        <ProfileRole />
        <ProfileBio handleInputChange={handleInputChange}/>
        <Badges />
      </div>
  )
}

export default ProfileContent