import React from "react";
import {
  FaHome,
  FaUser,
  FaFileAlt,
  FaClipboardList,
  FaChartBar,
} from "react-icons/fa";
import { defaultProfilePicture } from "../../data/static";
function Sidebar({setSelectedView, profile, username, selectedView}) {
  return (
    <div className="sidebar">
      <div className="sidebar-header">
        {profile.profile_picture ? (
          <img
            src={profile.profile_picture}
            alt=""
            className="profile-pic-sidebar"
          />
        ) : (
          <img
            src={defaultProfilePicture}
            alt=""
            className="profile-pic-sidebar"
          />
        )}
        <span>{username}</span>
      </div>
      <ul className="sidebar-menu">
        <li
          onClick={() => {
            setSelectedView("dashboard");
          }}
          className={selectedView === "dashboard" ? "selectedpage" : ""}
        >
          <FaHome /> Dashboard
        </li>
        <li
          onClick={() => {
            setSelectedView("profile");
          }}
          className={selectedView === "profile" ? "selectedpage" : ""}
        >
          <FaUser /> Profile
        </li>
        <li
          onClick={() => {
            setSelectedView("debates");
          }}
          className={selectedView === "debates" ? "selectedpage" : ""}
        >
          <FaClipboardList /> Debates
        </li>
        <li
          onClick={() => {
            setSelectedView("documents");
          }}
          className={selectedView === "documents" ? "selectedpage" : ""}
        >
          <FaFileAlt /> Documents
        </li>
        <li
          onClick={() => {
            setSelectedView("analytics");
          }}
          className={selectedView === "analytics" ? "selectedpage" : ""}
        >
          <FaChartBar /> Analytics
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
