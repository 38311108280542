import React, { useEffect, useState } from "react";
import mammoth from 'mammoth';
const RightPanel = ({ selectedFile }) => {
  const [fileContent, setFileContent] = useState(null);

    useEffect(() => {
      if (selectedFile) {
        const fileType = selectedFile.type;

        if (fileType.startsWith("image/")) {
          // Handle image files
          setFileContent(
            <img
              src={URL.createObjectURL(selectedFile.fileData)}
              alt={selectedFile.name}
              style={{
                maxWidth: "100%",
                borderRadius: "8px",
                objectFit: "contain",
              }}
            />
          );
        } else if (fileType === "application/pdf") {
          // Handle PDF files
          setFileContent(
            <iframe
              src={URL.createObjectURL(selectedFile.fileData)}
              title={selectedFile.name}
              style={{ width: "100%", height: "100vh", border: "none" }}
            />
          );
        } else if (fileType.startsWith("text/")) {
          // Handle text files
          const reader = new FileReader();
          reader.onload = (e) => {
            setFileContent(
              <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                {e.target.result}
              </pre>
            );
          };
          reader.readAsText(selectedFile.fileData);
        } else if (
          fileType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          // Handle .docx files
          const reader = new FileReader();
          reader.onload = (e) => {
            mammoth
              .convertToHtml({ arrayBuffer: e.target.result })
              .then((result) => {
                setFileContent(
                  <div
                    dangerouslySetInnerHTML={{ __html: result.value }}
                    style={{
                      padding: "16px",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                    }}
                  />
                );
              })
              .catch((error) => {
                console.error("Error processing .docx file:", error);
                setFileContent(
                  <p>
                    Error processing the .docx file. Please try a different
                    file.
                  </p>
                );
              });
          };
          reader.readAsArrayBuffer(selectedFile.fileData);
        } else {
          // Handle unsupported file types
          setFileContent(
            <div>
              <p>
                Preview not available for this file type.{" "}
                <a
                  href={URL.createObjectURL(selectedFile.fileData)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download {selectedFile.name}
                </a>
              </p>
              <p>
                Or you can{" "}
                <a
                  href={`https://docs.google.com/viewer?url=${encodeURIComponent(
                    URL.createObjectURL(selectedFile.fileData)
                  )}&embedded=true`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Open with Google Docs
                </a>
              </p>
            </div>
          );
        }
      } else {
        setFileContent(null);
      }
    }, [selectedFile]);
  return (
    <div className="file-list-panel" style={{ padding: "20px" }}>
      <h3>Selected Document</h3>
      {selectedFile ? (
        <div className="file-item" style={{ marginTop: "20px" }}>
          {fileContent || <p>Loading content...</p>}
        </div>
      ) : (
        <p>No document selected for preview.</p>
      )}
    </div>
  );
};

export default RightPanel;
