import React from "react";
import Bio from "../ProfileComponents/Bio";
import { useSelector } from "react-redux";

const ProfileBio = ({ handleInputChange }) => {
    const { profile,isEditting,errors } = useSelector((state) => state.profile); 

  return (
    <div className="bio-section">
      {isEditting ? (
        <>
          <textarea
            name="bio"
            value={profile.bio}
            onChange={handleInputChange}
            placeholder="Your bio"
            className="bio-input-profile"
           
          />
          {errors && <span className="error-message">{errors.bio}</span>}
        </>
      ) : (
          <Bio  /> 
      )}
    </div>
  );
};

export default ProfileBio;
