import React from "react";
import { motion } from "framer-motion";
import Scores from "./Scores";

export const getProgressBarColor = (percentage) => {
  if (percentage >= 75) {
    return "#fed7a5";
  } else if (percentage >= 50) {
    return "#c1e8ff";
  } else if (percentage >= 25) {
    return "#f7cbc5";
  } else {
    return "#fac7c3";
  }
};

const AnalysisHeader = ({ document, score }) => {
  const sentimentAnalysis = document.analysis_results.sentiment_analysis;

  if (!sentimentAnalysis) {
    return <p>Sentiment analysis data is not available.</p>;
  }

  return (
    <div className="analysis-header">
      <div className="sentiment-distribution">
        <h2>Sentiment Distribution</h2>
        {score && <p className="percentage">{score}</p>}

        <div className="sentiment-item">
          <span>Positive</span>
          <div className="progress-bar">
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: `${sentimentAnalysis.positive}%` }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
              style={{
                fontSize: "11px",
                color: "black",
                backgroundColor: getProgressBarColor(
                  sentimentAnalysis.positive
                ),
                textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
                fontWeight: "bold",
              }}
              className="progress positive"
            >
              {sentimentAnalysis.positive + "%"}
            </motion.div>
          </div>
        </div>

        <div className="sentiment-item">
          <span>Negative</span>
          <div className="progress-bar">
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: `${sentimentAnalysis.negative}%` }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
              style={{
                fontSize: "11px",
                color: "black",
                backgroundColor: getProgressBarColor(
                  sentimentAnalysis.negative
                ),
                textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
                fontWeight: "bold",
              }}
              className="progress negative"
            >
              {sentimentAnalysis.negative + "%"}
            </motion.div>
          </div>
        </div>

        <div className="sentiment-item">
          <span>Neutral</span>
          <div className="progress-bar">
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: `${sentimentAnalysis.neutral}%` }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
              style={{
                fontSize: "11px",
                color: "black",
                backgroundColor: getProgressBarColor(sentimentAnalysis.neutral),
                textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
                fontWeight: "bold",
              }}
              className="progress neutral"
            >
              {sentimentAnalysis.neutral + "%"}
            </motion.div>
          </div>
        </div>
      </div>

      <Scores document={document} />
    </div>
  );
};

export default AnalysisHeader;
