import img1 from '../assets/img1.png';
import img2 from '../assets/img2.png';
import img3 from '../assets/img3.png';
import im4 from '../assets/im4.png';
import img5 from '../assets/img5.png';
import img6 from '../assets/img6.png';
import { faCrown } from '@fortawesome/free-solid-svg-icons'; // Import the specific icon from FontAwesome

export const defaultProfilePicture = "https://www.gravatar.com/avatar/?d=mp"; 
export const defaultImage = "https://via.placeholder.com/400x350?text=No+Image+Available";
export const getDebateFormat = (format) => {
    if (format === '2x2 British Parliamentary Debate (2 teams - 4 speakers)') {
        return 'BP';
    } else if (format === '1x1 Lincoln-Douglas') {
        return 'LD';
    } else {
        return 'Unknown Format'; // Fallback for unknown formats
    }
}

// Example usage:
console.log(getDebateFormat('2x2 British Parliamentary Debate (2 teams - 4 speakers)')); // Outputs 'BP'
console.log(getDebateFormat('1x1 Lincoln-Douglas')); // Outputs 'LD'


export const sections = [
    {
        title: 'AI Debates',
        description: 'Debate with AI and humans on any topic',
        url: img1, // Corrected: Removed curly braces
    },
    {
        title: 'University Features',
        description: "Manage your university's debate team",
        url: img2, // Corrected: Removed curly braces
    },
    {
        title: 'Tutorials',
        description: 'Learn how to debate with our AI tutor',
        url: img3, // Corrected: Removed curly braces
    },
    {
        title: 'Student Debates',
        description: 'Compete in student debates for prizes',
        url: im4, // Corrected: Removed curly braces
    },
    {
        title: 'University Accounts',
        description: "Manage your university's debate team",
        url: img5, // Corrected: Removed curly braces
    },
    {
        title: 'Tournaments',
        description: 'Compete in student debates for prizes',
        url: img6, // Corrected: Removed curly braces
    },
];

export const formatDate = (dateString) => {
    if (!dateString) return "Schedule the debate for later.";
    const date = new Date(dateString);
    const options = {
        weekday: "long", // Monday, Tuesday, etc.
        year: "numeric", // Full year
        month: "long", // Full month name
        day: "numeric", // Day of the month
        hour: "2-digit", // Hour in 2-digit format
        minute: "2-digit", // Minute in 2-digit format
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
};


export const roles = [
    {
        role: "Prime Minister",
        description:
        "The Prime Minister speaks first and last, and has the right to define the motion.",
        icon: faCrown, // Example: FontAwesome or custom icon name
    },
    {
        role: "Deputy Prime Minister",
        description:
        "The Deputy Prime Minister speaks second and last, and has the right to define the motion.",
        icon: faCrown, // Example: FontAwesome or custom icon name
    },
    {
        role: "Leader of Opposition",
        description:
        "The Leader of Opposition speaks third and last, and has the right to define the motion.",
        icon: faCrown, // Example: FontAwesome or custom icon name
    },
    {
        role: "Deputy Leader of Opposition",
        description:
        "The Deputy Leader of Opposition speaks fourth and last, and has the right to define the motion.",
        icon: faCrown, // Example: FontAwesome or custom icon name
    },
];

export const clearLocalStorage = ()=>{
         localStorage.removeItem("selectedCategory");
         localStorage.removeItem("motion");
         localStorage.removeItem("topic");
         localStorage.removeItem("title");
         localStorage.removeItem("categoryId");
         localStorage.removeItem("selectedFormat");
         localStorage.removeItem("selectedCategory");
}


export const prettyDate = (dateString) => {
    console.log(typeof(dateString))
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(date);
};

