import React, { useEffect, useState } from "react";
import "./DocumentAnalysis.css";
import { FaArrowLeft } from "react-icons/fa";
import PotentialRebuttals from "./PotentialRebuttals";
import CounterArguments from "./CounterArguments";
import AnalysisContainer from "./AnalysisContainer";
import AnalysisHeader from "./AnalysisHeader";

const DocumentAnalysis = ({ document, onBack }) => {
  console.log(document);
  const [score, setScore] = useState(0);

  useEffect(() => {
    if (document.analysis_results.sentiment_analysis) {
      const score =
        document.analysis_results.sentiment_analysis.positive +
        document.analysis_results.sentiment_analysis.negative +
        document.analysis_results.sentiment_analysis.neutral;
      console.log("Score: " + score);
      setScore(score);
    }
  }, [document.analysis_results.sentiment_analysis]);

  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="document-analysis">
        <button
          style={{ display: "flex", alignItems: "center" }}
          className="btn-back"
          onClick={onBack}
        >
          <FaArrowLeft style={{ marginRight: "5px" }} />
          Back to Documents
        </button>

        <h1 style={{ textAlign: "center" }}>Insights from Document Analyzer</h1>
        <p
          style={{
            textAlign: "center",
            marginBottom: "40px",
            color: "#4F7396",
          }}
        >
          AI-powered insights for your structured debates
        </p>

        {/* Sentiment Distribution and Scores */}
        <AnalysisHeader document={document} score={score} />
        {/* Key Points and Supporting Evidence */}
        <AnalysisContainer document={document} />
        {/* Counter Arguments */}
        <CounterArguments document={document} />
        {/* Potential Rebuttals */}
        <PotentialRebuttals document={document} />
      </div>
    </>
  );
};

export default DocumentAnalysis;
