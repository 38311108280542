import "./ConfirmationDialog";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";
const ConfirmationDialog = ({ isDialogOpen, handleDialogClose,mightSelectedRole,handleDialogConfirm }) => {
  console.log("confirmation dialog");

  if (!isDialogOpen) return null; // Do not render if the dialog is not open

    return (
        <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="enhanced-dialog-title"
        aria-describedby="enhanced-dialog-description"
        sx={{
            '& .MuiPaper-root': {
            borderRadius: 3,
            padding: '20px',
            minWidth: '400px',
            backgroundColor: '#f4f6f8',
            },
        }}
        >
        <DialogTitle
            sx={{
            fontWeight: 'bold',
            fontSize: '1.25rem',
            textAlign: 'center',
            borderBottom: '1px solid #e0e0e0',
            paddingBottom: '10px',
            color: '#333',
            }}
        >
            Confirm Registration
        </DialogTitle>
        <DialogContent>
            <DialogContentText
            sx={{
                margin: '20px 0',
                fontSize: '1rem',
                color: '#666',
                textAlign: 'center',
            }}
            >
            Are you sure you want to register as {mightSelectedRole}?
            </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
            onClick={handleDialogClose}
            variant="outlined"
            sx={{
                margin: '0 10px',
                borderColor: '#f44336',
                color: '#f44336',
                '&:hover': {
                backgroundColor: '#ffebee',
                },
            }}
            >
            Cancel
            </Button>
            <Button
            onClick={handleDialogConfirm}
            variant="contained"
            sx={{
                margin: '0 10px',
                backgroundColor: '#4caf50',
                color: '#fff',
                '&:hover': {
                backgroundColor: '#388e3c',
                },
            }}
            >
            Confirm
            </Button>
        </DialogActions>
    </Dialog>
    );
};
export default ConfirmationDialog;