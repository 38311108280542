import React from "react";
import { useSelector } from "react-redux";
import { defaultProfilePicture } from "../../data/static";


const ProfilePicture = ({ handleImageChange }) => {
  // Access Redux state directly using useSelector
  const { profile, isEditting, previewPicture, errors } = useSelector((state) => state.profile);

  const imageToShow = isEditting && previewPicture ? previewPicture : profile.profile_picture || defaultProfilePicture;

  return (
    <div className="profile-pic-section">
      <div className="profile-pic-wrapper">
        <img src={imageToShow} alt="Profile" className="profile-pic" />

        {isEditting && (
          <>
          <div className="upload-overlay">
            {(errors && errors.profile_picture)?
             <span className="error-message">{errors.profile_picture}</span>:
             <label htmlFor="profile-pic-upload" className="upload-label">
              Change Profile Picture
            </label>}
            
            <input
              type="file"
              id="profile-pic-upload"
              accept="image/*"
              onChange={handleImageChange}
              className="upload-input"
            />
          </div>
            
          </>
          
        )}
      </div>
    </div>
  );
};

export default ProfilePicture;
