import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { Pie } from "react-chartjs-2";
import { motion, useInView } from "framer-motion";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Statistics = () => {
  console.log("statistics");

    const debateStatsData = {
        labels: ["Political", "Social", "Technology", "Education", "Environment"],
        datasets: [
        {
            label: "Debate Topics",
            data: [40, 20, 15, 15, 10],
            backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#FF9F40",
            "#4BC0C0",
            ],
            hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#FF9F40",
            "#4BC0C0",
            ],
        },
        ],
    };

    const engagementData = {
        labels: ["Moderated Debates", "Unmoderated Debates"],
        datasets: [
        {
            data: [70, 30],
            backgroundColor: ["#4BC0C0", "#FF9F40"],
            hoverBackgroundColor: ["#36A2EB", "#FFCE56"],
        },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
        legend: {
            position: "top",
        },
        title: {
            display: true,
            text: "Types of Debates",
        },
        },
    };

    // Ref for scroll-triggered animation
    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: true, margin: "-100px" });

    return (
        <Box mt={8} textAlign="center" className="statistics-section" ref={ref}>
        <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#2c3e50" }}
        >
            Key Debate Statistics
        </Typography>
        <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={
            isInView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.9 }
            }
            transition={{ duration: 0.8 }}
            style={{ marginTop: "40px" }}
        >
            <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ padding: "30px", borderRadius: "12px" }}>
                <motion.div
                    initial={{ opacity: 0, y: 30 }}
                    animate={
                    isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }
                    }
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "#2c3e50" }}
                    >
                    Debate Topics
                    </Typography>
                    <Box style={{ height: "400px" }}>
                    <Pie
                        data={debateStatsData}
                        options={{ responsive: true, maintainAspectRatio: false }}
                    />
                    </Box>
                </motion.div>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ padding: "30px", borderRadius: "12px" }}>
                <motion.div
                    initial={{ opacity: 0, y: 30 }}
                    animate={
                    isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }
                    }
                    transition={{ duration: 0.5, delay: 0.4 }}
                >
                    <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "#2c3e50" }}
                    >
                    Types of Debates
                    </Typography>
                    <Box style={{ height: "400px" }}>
                    <Pie data={engagementData} options={options} />
                    </Box>
                </motion.div>
                </Paper>
            </Grid>
            </Grid>
        </motion.div>
        </Box>
    );
};

export default Statistics;
