// src/components/TokenHandler.js
import axios from 'axios';
import config from '../config';

const refreshAccessToken = async (refreshToken) => {
    console.log("token handler");
    try {
        const response = await axios.post(`${config.apiUrl}/token/refresh/`, {
            refresh: refreshToken
        });
        return response.data.access;
    } catch (error) {
        console.error('Error refreshing access token:', error);
        return null;
    }
};

export default refreshAccessToken;
