import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
const SearchBar = ({token, query, setQuery, setDebatesBySearch, debatesBySearch}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // use use effect to call debates based on search query
  useEffect(()=>{
    const fetchData = async ()=>{
      setLoading(true);
      setError(null);
      try{
        if(query.trim !== ""){
           const response = await axios.get(`${config.apiUrl}/debates/search/?q=${query}`,
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
          console.log("response: "+response.data);
          setDebatesBySearch(response.data);
        }
         
      }
      catch(error){
        setError("error fetching debates based on search query");
      }
      finally{
         setLoading(false);
      }
    }
    fetchData();
  },[query, token, setDebatesBySearch]);

    return (
        <div className="search-bar">
        <input type="text" placeholder="Search debates" onChange={(event)=>{setQuery(event.target.value)}} />
        {loading && (
              <div className="loading-spinner-createdebate">
                <div className="spinner-createdebate"></div>
              </div>
            )}
        {error && <div>{error}</div>}
        {(query.trim() !== "" && debatesBySearch.length === 0 && !loading) && (
          <span className="error-message-search-bar">No debates found matching your search query. Try checking the spelling or using different keywords.</span>
      )}

        </div>
    );
};

export default SearchBar;
