import React from 'react'

const EthicalConsiderayion = ({document}) => {
  return (
    <div style={{flex:'1'}} className="ethical-considerations-section">
      <div className="ethical-considerations">
        <h2>
          Ethical Considerations (
          {document.analysis_results.ethical_considerations
            ? document.analysis_results.ethical_considerations.length
            : 0}
          )
        </h2>
      </div>

      {/* Ethical Considerations Grid */}
      <div className="ethical-considerations-grid">
        {document.analysis_results.ethical_considerations &&
        document.analysis_results.ethical_considerations.length > 0 ? (
          document.analysis_results.ethical_considerations.map(
            (consideration, index) => (
              <div className="grid-item" key={index}>
                <p>{consideration}</p>
              </div>
            )
          )
        ) : (
          <p>No ethical considerations available.</p>
        )}
      </div>
    </div>
  );
}

export default EthicalConsiderayion