import React from "react";
import { motion } from "framer-motion";

const AnalysisContainer = ({ document }) => {
  return (
    <div className="analysis-container">
      <div className="analysis-summary">
        {/* Key Points Summary */}
        <motion.div
          className="summary-item key-points"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
        >
          <h2>Key Points</h2>
          <p>{document.analysis_results.key_points.length}</p>
        </motion.div>

        {/* Supporting Evidence Summary */}
        <motion.div
          className="summary-item supporting-evidence"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
        >
          <h2>Supporting Evidence</h2>
          <p>{document.analysis_results.supporting_evidence.length}</p>
        </motion.div>
      </div>

      <div className="analysis-columns">
        {/* Key Points Grid */}
        <div className="analysis-grid key-points-grid">
          {document.analysis_results.key_points &&
          document.analysis_results.key_points.length > 0 ? (
            document.analysis_results.key_points.map((point, index) => (
              <motion.div
                className="grid-item"
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  duration: 0.5,
                  ease: "easeOut",
                  delay: index * 0.1,
                }}
              >
                <p>{point}</p>
              </motion.div>
            ))
          ) : (
            <p>No key points available.</p>
          )}
        </div>

        {/* Supporting Evidence Grid */}
        <div className="analysis-grid supporting-evidence-grid">
          {document.analysis_results.supporting_evidence &&
          document.analysis_results.supporting_evidence.length > 0 ? (
            document.analysis_results.supporting_evidence.map(
              (evidence, index) => (
                <motion.div
                  className="grid-item"
                  key={index}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{
                    duration: 0.5,
                    ease: "easeOut",
                    delay: index * 0.1,
                  }}
                >
                  <p>{evidence}</p>
                </motion.div>
              )
            )
          ) : (
            <p>No supporting evidence available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnalysisContainer;
