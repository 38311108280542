import React from 'react'
import { useSelector } from 'react-redux';

const Bio = () => {
    const { profile } = useSelector((state) => state.profile); 

  return (
    <div className="profile-bio">
      <h3>Biography</h3>
      {profile.bio ? profile.bio : <p>No Bio is available</p>}
      <p></p>
    </div>
  );
}

export default Bio