import React, { useEffect, useState } from "react";
import NotificationsReminders from "./NotificationsReminders";
function Dashboard({ username, documents = [], setViewingAnalysis }) {
  const [totalWords, setTotalWords] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalInReview, setTotalInReview] = useState(0);
  const [totalAnalysised, setTotalAnalysised] = useState(0);



  useEffect(()=>{
     setViewingAnalysis(false);
  },[setViewingAnalysis]);


  
  useEffect(() => {
    if (documents && documents.length > 0) {
      const totalWords = documents.reduce(
        (acc, curr) => acc + (curr.analysis_results?.word_count || 0),
        0
      );
      const totalDocuments = documents.length;
      setTotalWords(totalWords);
      setTotalDocuments(totalDocuments);
      const totalInReview = documents.filter(
        (doc) => doc.analysis_status !== "completed"
      ).length;
      setTotalInReview(totalInReview);
      setTotalAnalysised(documents.length - totalInReview);
    }
  }, [documents]);

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <h2>Welcome back, {username}</h2>
        <p>Here's your weekly summary</p>
      </div>

      <h3>Your activity</h3>

      <div className="activity-section">
        <div className="activity-box">
          100 <span>Total debates</span>
        </div>
        <div className="activity-box">
          120 <span>Total feedbacks</span>
        </div>
        <div className="activity-box">
          90 <span>Total AI feedbacks</span>
        </div>
        <div className="activity-box">
          10 <span>Total AI debates</span>
        </div>
        <div className="activity-box">
          30 <span>Total AI feedbacks</span>
        </div>
      </div>

      <h3>Debate Documents</h3>
      <div className="documents-section">
        <div className="document-box">
          {totalDocuments} <span>Total Docs</span>
        </div>
        <div className="document-box">
          {totalWords} <span>Total Words</span>
        </div>
        <div className="document-box">
          {totalInReview} <span>In Review</span>
        </div>
        <div className="document-box">
          {totalAnalysised} <span>Published</span>
        </div>
      </div>

      <NotificationsReminders />
    </div>
  );
}

export default Dashboard;
