import React from "react";
import Hero from "./landingPageComponents/Hero";
import "./LandingPage.css";
import AboutUs from "./landingPageComponents/AboutUs";
import GetStarted from "./landingPageComponents/GetStarted";
import Statistics from "./landingPageComponents/Statistics";
import { clearLocalStorage } from "../data/static";
const LandingPage = ({token}) => {
  
    clearLocalStorage();
    return (
      <>
        <Hero />
        <AboutUs />
        <Statistics/>
        <GetStarted token={token}/>
      </>
    );
      
}

export default LandingPage;
