import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import config from "../config";
import "./DebateDetail.css";
import ArgumentTypology from "./ArgumentTypology";
import Hero from "./DebateDetailsComponent/Hero";
import Teams from "./DebateDetailsComponent/Teams";
import { useDispatch, useSelector } from "react-redux";
import ButtonsSet from "./DebateDetailsComponent/ButtonsSet";
import ConfirmationDialog from "./DebateRoles/ConfirmationDialog";
import { closeDialog } from "../redux/userSlice";
import { FaUpload } from "react-icons/fa"; // Importing the upload icon

const DebateDetail = ({ token }) => {
  console.log("debate details");
  const { id } = useParams();
  const [debate, setDebate] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [argumentsList, setArgumentsList] = useState([]);
  const [commentsList, setCommentsList] = useState([]);
  const [factChecksList, setFactChecksList] = useState([]);
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("Teams");
  const isDialogOpen = useSelector((state) => state.user.isDialogOpen);
  const mightSelectedRole = useSelector(
    (state) => state.user.mightSelectedRole
  );
  const dispatch = useDispatch();
  const [userName, setUserName] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Add a loading state
  const [userRole, setUserRole] = useState(null);

  // Load username, roleTemplates, and roleAssignments from localStorage when the component mounts
  useEffect(() => {
    const storedUsername = localStorage.getItem("username") || "";
    setUserName(storedUsername);
  }, [dispatch, userName]);

  // Fetch debates
  useEffect(() => {
    if (!userName) return; // Wait for the username to be loaded from localStorage or Redux
    axios
      .get(`${config.apiUrl}/debates/${id}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDebate(response.data);
        localStorage.setItem(
          "roleTemplates",
          JSON.stringify(response.data.format.role_templates)
        );
        const roleAssignments = response.data.role_assignments;
        if (roleAssignments) {
          // Save roleAssignments to localStorage and update Redux
          localStorage.setItem(
            "roleAssignments",
            JSON.stringify(roleAssignments)
          );
        }

        // Find the role for the current user by username
        const userRoleAssignment = roleAssignments.find(
          (assignment) =>
            assignment.user && assignment.user.username === userName
        );

        // If the user's role is found, update it in the Redux store and localStorage
        if (userRoleAssignment) {
          setUserRole(userRoleAssignment.role_template.name);
          localStorage.setItem(
            "userRole",
            userRoleAssignment.role_template.name
          );
        } else {
          // Clear userRole from localStorage if not found
          setUserRole(null);
          localStorage.removeItem("userRole");
        }
      })
      .catch((error) => console.error("Error fetching debate details:", error));
  }, [id, token, dispatch, userName]);

  useEffect(() => {
    if (tabIndex === 0) {
      axios
        .get(`${config.apiUrl}/arguments/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            debate: id,
          },
        })
        .then((response) => setArgumentsList(response.data))
        .catch((error) => console.error("Error fetching arguments:", error));
    } else if (tabIndex === 1) {
      axios
        .get(`${config.apiUrl}/comments/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            debate: id,
          },
        })
        .then((response) => setCommentsList(response.data))
        .catch((error) => console.error("Error fetching comments:", error));
    } else if (tabIndex === 2) {
      axios
        .get(`${config.apiUrl}/factchecks/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            debate: id,
          },
        })
        .then((response) => setFactChecksList(response.data))
        .catch((error) => console.error("Error fetching fact checks:", error));
    }
  }, [tabIndex, id, token]);

  const handleButtonClick = (buttonName, index) => {
    setActiveButton(buttonName);
    if (index !== undefined && index !== null) {
      handleTabChange(index);
    }
  };

  const handleJoinMeeting = () => {
    navigate(`/debate/${debate.id}/meeting`, {
      state: { topic: debate.topic },
    });
  };

  const handleTabChange = (newValue) => {
    setTabIndex(newValue);
  };

  const handleDialogClose = () => {
    dispatch(closeDialog()); // Close the confirmation dialog
  };

  const handleDialogConfirm = () => {
    if (mightSelectedRole) {
      console.log("before sending the post reuqest, :" + mightSelectedRole);
      setIsLoading(true);
      // Make API call to register the role
      axios
        .post(
          `${config.apiUrl}/debates/${id}/register/`,
          {
            role: mightSelectedRole,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            // Registration succeeded, now fetch the updated debate details
            axios
              .get(`${config.apiUrl}/debates/${id}/`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((updatedResponse) => {
                // Update the debate state with the new data
                setDebate(updatedResponse.data);
                setUserRole(mightSelectedRole);
                dispatch(closeDialog());
                localStorage.setItem("userRole", mightSelectedRole);
                console.log("debatedetails/ debate data after fetching\n");
                console.log(updatedResponse.data);
                navigate(`/debate/${id}/upload-document`);
              })
              .catch((fetchError) => {
                console.error(
                  "Error fetching updated debate details:",
                  fetchError
                );
              });
          } else {
            console.error("Error: Registration not successful");
          }
        })
        .catch((error) => console.error("Error registering for role:", error))
        .finally(() => setIsLoading(false)); // Stop loading after the API call completes
    }
  };

  function handleUploadingDocumentClick() {
    // check if the user is valid to upload documents in this debate (if he has role in this debate)
    if (userRole && userRole.trim() !== null) {
      navigate(`/debate/${id}/upload-document`);
    } else {
      alert("You should have a role in this debate to upload documents");
    }
  }

  if (!debate) return <div>Loading...</div>;
  return (
    <div className="debate-details-section">
      <Hero debate={debate} />
      <div className="join-button-div">
        <button onClick={handleJoinMeeting}>Join Meeting</button>
        <button
          onClick={handleUploadingDocumentClick}
          style={{ backgroundColor: "#E8EDF2", color: "black" }}
          className="btn-upload"
        >
          <FaUpload style={{ marginRight: "5px", fontSize: "16px" }} />
          Upload document
        </button>
      </div>

      <ButtonsSet
        activeButton={activeButton}
        handleButtonClick={handleButtonClick}
      />

      {/* Content based on active button */}
      <div className="tab-content" style={{ width: "100%" }}>
        {activeButton === "Teams" ? (
          isLoading ? (
            <p>Loading...</p> // Show loading indicator while fetching data
          ) : (
            <>
              <Teams debate={debate} id={id} />
              {/* <DebateRegistration debateId={id} token={token} /> */}
            </>
          )
        ) : null}

        {activeButton === "Arguments" &&
          argumentsList.map((arg) => <p key={arg.id}>{arg.content}</p>)}
        {activeButton === "Comments" &&
          commentsList.map((comment) => (
            <p key={comment.id}>{comment.content}</p>
          ))}
        {activeButton === "Fact Checks" &&
          factChecksList.map((factCheck) => (
            <p key={factCheck.id}>{factCheck.fact}</p>
          ))}
        {activeButton === "Debate Structure" && (
          <div style={{ marginTop: "16px" }}>
            <h4 style={{ marginBottom: "8px" }}>
              Argument Structure for: {debate.topic}
            </h4>
            {/* Render the ArgumentTypology component */}
            <ArgumentTypology />
          </div>
        )}
      </div>

      <ConfirmationDialog
        isDialogOpen={isDialogOpen}
        handleDialogClose={handleDialogClose}
        mightSelectedRole={mightSelectedRole}
        handleDialogConfirm={handleDialogConfirm}
      />
    </div>
  );
};

export default DebateDetail;
