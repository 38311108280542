import React from "react";
import "./ProgressBar.css"; // Custom styling

const ProgressBar = ({ progress}) => {
  

  return (
    <div className="progress-bar-container">
      <div className="progress-bar-background">
        <div
          className="progress-bar-foreground"
          style={{ width: `${progress}` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
