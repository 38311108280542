import React, { useState, useEffect } from "react";
import "./DebateRoles.css";
import { useDispatch} from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { openDialog } from "../../redux/userSlice";
import axios from "axios";
import config from "../../config";
const DebateRoles = ({ token }) => {
  console.log("debate roles");

  const [debate, setDebate] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userName, setUserName] = useState(
    null || localStorage.getItem("username")
  );
  const { id } = useParams();

  const [selectedRole, setSelectedRole] = useState(
    localStorage.getItem("userRole") || ""
  ); // Initialize selectedRole from Redux or localStorage
  const [notification, setNotification] = useState(""); // State to manage notification messages
  const [roleTemplates, setRoleTemplates] = useState(
    null || localStorage.getItem("roleTemplates")
  );
  const [roleAssignments, setRoleAssignments] = useState(
    null || localStorage.getItem("roleAssignments")
  );
  const [userRole, setUserRole] = useState(
    null || localStorage.getItem("userRole")
  );

  // Fetch debates
  useEffect(() => {
    if (!userName) return; // Wait for the username to be loaded from localStorage
    axios
      .get(`${config.apiUrl}/debates/${id}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDebate(response.data);

        const roleTemplates = response.data.format.role_templates;
        if (roleTemplates) {
          localStorage.setItem(
            "roleTemplates",
            JSON.stringify(response.data.format.role_templates)
          );
          setRoleTemplates(roleTemplates);
        }

        const roleAssignments = response.data.role_assignments;
        if (roleAssignments) {
          // Save roleAssignments to localStorage and update state
          localStorage.setItem(
            "roleAssignments",
            JSON.stringify(roleAssignments)
          );
          setRoleAssignments(roleAssignments);
        }

        // Find the role for the current user by username
        const userRoleAssignment = roleAssignments.find(
          (assignment) =>
            assignment.user && assignment.user.username === userName
        );

        // If the user's role is found, update it in state and localStorage
        if (userRoleAssignment) {
          setUserRole(userRoleAssignment.role_template.name);
          localStorage.setItem(
            "userRole",
            userRoleAssignment.role_template.name
          );
        } else {
          // Clear userRole from localStorage if not found
          setUserRole(null);
          localStorage.removeItem("userRole");
        }
      })
      .catch((error) => console.error("Error fetching debate details:", error));
  }, [id, token, dispatch, userName]);

  // Auto-dismiss notification after a few seconds
  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => setNotification(""), 3000); // Dismiss after 3 seconds
      return () => clearTimeout(timer); // Cleanup timer
    }
  }, [notification]);

  const handleRoleClick = (newRole) => {
    console.log("Current User Role:", userRole);
    console.log("New Role:", newRole);
    if (userRole && userRole !== newRole) {
      setNotification("You cannot change your role once it is assigned.");
    } else {
      setSelectedRole(newRole);
      dispatch(openDialog(newRole));
      navigate(-1);
    }
  };

  const isRoleAssigned = (roleName) => {
    if (!Array.isArray(roleAssignments)) {
      return false; // Return false if roleAssignments is not an array
    }
    return roleAssignments.some(
      (assignment) =>
        assignment.role_template.name === roleName && assignment.user !== null
    );
  };

  return (
    <div className="roles-container">
      {/* Notification Bar */}
      {notification && <div className="notification-bar">{notification}</div>}

      <h1 className="par-h1">Participate in a Debate</h1>
      <h2 className="role-h2">Role</h2>
      <section className="roles-section">
        {roleTemplates &&
        Array.isArray(roleTemplates) &&
        roleTemplates.length > 0 ? (
          roleTemplates.map((role, index) => {
            const roleAssigned = isRoleAssigned(role.name);

            return (
              <div key={index} className="role-item">
                <div>
                  <h4 style={{ margin: "0", fontSize: "medium" }}>
                    {role.name}
                  </h4>
                  <p style={{ margin: "0", color: "#4F7396" }}>
                    {role.description}
                  </p>
                </div>
                <button
                  onClick={() => handleRoleClick(role.name)}
                  disabled={selectedRole === role.name || roleAssigned}
                  className={
                    selectedRole === role.name || roleAssigned
                      ? "disabled-button"
                      : ""
                  }
                >
                  Assign
                </button>
              </div>
            );
          })
        ) : (
          <p>No roles available at this time.</p> // Fallback UI if roleTemplates is empty or not loaded yet
        )}
      </section>
    </div>
  );
};

export default DebateRoles;
