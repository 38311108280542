// src/components/Voting.js
import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';
import config from '../config';  // Import the config file

const Voting = ({ token }) => {
    console.log("voting");
    const { id } = useParams();
    const [vote, setVote] = useState(null);

    const handleVote = (voteType) => {
        axios.post(`${config.apiUrl}/debates/${id}/vote/`, { vote: voteType }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => setVote(response.data))
        .catch(error => console.error('Error submitting vote:', error));
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Vote on Debate</Typography>
            <Box mt={2}>
                <Button variant="contained" onClick={() => handleVote('pro')} color="primary">
                    Vote Pro
                </Button>
                <Button variant="contained" onClick={() => handleVote('con')} color="secondary" style={{ marginLeft: '10px' }}>
                    Vote Con
                </Button>
            </Box>
            {vote && <Typography variant="h6" style={{ marginTop: '20px' }}>Thank you for voting! Your vote: {vote.vote}</Typography>}
        </Container>
    );
};

export default Voting;
