import React from "react";
import { useNavigate } from "react-router-dom";
import "./SuccessDialog.css"; // Importing the CSS file for styling

export default function SuccessDialog({ isOpen, onClose,message, welcome, buttonText }) {
  const navigate = useNavigate();

  const handleDialogClose = () => {
    // Trigger close and navigate to home
    onClose();
    navigate("/"); // Redirect to home after closing the dialog
  };

  if (!isOpen) {
    return null; // Don't render anything if dialog is not open
  }

  return (
    <div className="dialog-overlay">
      <div className="dialog-box">
        <div className="dialog-header">
          <h2>🎉 Success!</h2>
        </div>
        <div className="dialog-body">
          <p>
            {message || ''}
            <br />
            {welcome || ''}
            
          </p>
        </div>
        <div className="dialog-footer">
          <button className="dialog-button" onClick={handleDialogClose}>
           {buttonText || ''}
          </button>
        </div>
      </div>
    </div>
  );
}
