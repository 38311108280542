import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../config';
// Async thunk to handle profile update
export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  async ({ formData, token }, { rejectWithValue }) => {
    try {
      // Make PUT request to update profile using the provided FormData
      const response = await axios.put(
        `${config.apiUrl}/profiles/update_me/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Use the token directly
            // Don't set Content-Type manually; Axios handles it for FormData
          },
        }
      );

      // Return the response data if the request is successful
      return response.data;
    } catch (error) {
      // Return any error messages as rejected value for better error handling
      return rejectWithValue(error.response?.data || "An error occurred while updating the profile.");
    }
  }
);




const profileSlice = createSlice({
    name: 'profile',
    initialState:{
        profile:{
            profile_picture: '',
            bio:"",
            interests:"",
            first_name:"",
            last_name:"",
            gender:"",
            birthdate:"",
            phone_number:"",
            address:"",
            education:"",
            current_job:"",
        },
        backupProfile: {},
        previewPicture: '',
        newPicture: null,
        errors:{},
        isEditting:false,
        status:'idle',
    },
    reducers:{
        setProfile:(state,action)=>{
            state.profile = {...state.profile, ...action.payload};
        },
        setBackupProfile:(state)=>{
            state.backupProfile = state.profile;
        },
       setNewPicture: (state, action) => {
            if (action.payload === null) {
                // Handle case when the payload is null (e.g., when cancelling the edit)
                state.newPicture = null;
                state.previewPicture = '';
            } else {
                // Handle case when a new picture is being set
                state.newPicture = action.payload.file;
                state.previewPicture = action.payload.previewUrl;
            }

            // Ensure errors is an object and clear profile_picture errors
            if (state.errors) {
                state.errors.profile_picture = null;
            } else {
                state.errors = {};
            }
        },


        setErrors:(state,action)=>{
            state.errors = action.payload;
        },
        setIsEditing:(state,action)=>{
            state.isEditting = action.payload;
        },
        clearErrors:(state)=>{
            state.errors = {};
        }
    },
    extraReducers:(builder)=>{
        builder.addCase(updateProfile.pending, (state)=>{
            state.status = 'loading';
        })
        .addCase(updateProfile.fulfilled, (state,action)=>{
            state.status = 'succeeded';
            state.profile = action.payload;
            state.previewPicture = action.payload.profile_picture;
            state.isEditting = false;
        })
        .addCase(updateProfile.rejected, (state,action)=>{
            state.status = 'failed';
            state.errors = action.payload;
        })
    }
});

export const {
    setProfile,
    setNewPicture,
    setErrors,
    setIsEditing,
    clearErrors,
    setBackupProfile
} = profileSlice.actions;

export default profileSlice.reducer;