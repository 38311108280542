import React, { useState, useEffect } from "react";
import './Pagination.css';
const Pagination = ({
  debatesPerPage,
  totalDebates,
  paginate,
  currentPage,
}) => {
  const [pageNumbers, setPageNumbers] = useState([]);

  // Update page numbers whenever debatesPerPage or totalDebates change
  useEffect(() => {
    const totalPages = Math.ceil(totalDebates / debatesPerPage);
    const numbersArray = [];
    for (let i = 1; i <= totalPages; i++) {
      numbersArray.push(i);
    }
    setPageNumbers(numbersArray);
  }, [debatesPerPage, totalDebates]);

  return (
    <div className="pagination-nav">
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className={currentPage === number ? "active" : ""}>
            <button onClick={() => paginate(number)} className="page-link">
              {number}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Pagination;
